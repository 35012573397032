import React from "react";

import { Badge, BadgeProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  anchorTopRight: {
    transform: "translate(-0%, -50%)",
  },
}));

const CardItem = (props: BadgeProps) => {
  const classes = useStyles();

  return (
    <Badge
      classes={{
        anchorOriginTopRightRectangle: classes.anchorTopRight,
      }}
      {...props}
    />
  );
};

export default CardItem;
