import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import Icons from "../Icon";
import InputBase from "@material-ui/core/InputBase";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.primary.light,
    },
    searchIcon: {
      color: "#fff",
    },
    listItemText: { borderRadius: 8, background: "#fff", padding: "2px 12px" },
  })
);

export interface ListSearchInputProps {
  value?: string;
  onChange?: (s: string) => void;
  style?: React.CSSProperties;
  component?: any;
  autoFocus?: boolean;
  placeholder?: string;
  loading?: boolean;
}
function ListSearchInput(props: ListSearchInputProps) {
  const { value, onChange, style, component, autoFocus, placeholder, loading } =
    props;
  const classes = useStyles();

  return (
    <ListItem className={classes.root} style={style} component={component}>
      <ListItemAvatar>
        <IconButton disabled>
          {loading ? (
            <CircularProgress style={{ color: "#fff" }} size={24} />
          ) : (
            <Icons.Search className={classes.searchIcon} />
          )}
        </IconButton>
      </ListItemAvatar>
      <ListItemText
        className={classes.listItemText}
        primary={
          <InputBase
            fullWidth
            autoFocus={autoFocus}
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
            placeholder={placeholder}
          />
        }
      />
    </ListItem>
  );
}

export default ListSearchInput;
