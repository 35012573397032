import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fileDisplayView,
  loadFile,
  loadFileData,
  getSignedDownloadURL,
} from "ducks/files";
import { AppDispatch, RootState } from "ducks/state";
import { uploadOneFile } from "ducks/prescriptionLayout";
import { downloadUrlParams } from "@udok/lib/api/models";
import {
  useExpiringLocalConfig,
  SettingsKeys,
} from "@udok/lib/hooks/localConfig";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export const useFileLoader = (fileID: string) => {
  const [loading, setL] = React.useState(false);
  const [failed, setF] = React.useState(false);
  const [dataurl, setD] = React.useState("");
  const dispatch: AppDispatch = useDispatch();

  const { src, filename } = useSelector((state: RootState) =>
    fileDisplayView(state, { fileID })(state)
  );

  const reload = React.useCallback(() => {
    setL(true);
    if (fileID) {
      Promise.all([dispatch(loadFileData(fileID)), dispatch(loadFile(fileID))])
        .finally(() => {
          setL(false);
        })
        .then(() => setF(false))
        .catch(() => {
          setF(true);
        });
    }
  }, [fileID, dispatch]);

  React.useEffect(() => {
    reload();
  }, [fileID, reload]);

  React.useEffect(() => {
    if (!src) {
      return;
    }
    var a = new FileReader();
    a.onload = function (e) {
      setD(String(e?.target?.result ?? ""));
    };
    a.readAsDataURL(src);
  }, [src, setD]);

  return [dataurl, filename, loading, failed] as [
    string,
    string,
    boolean,
    boolean
  ];
};

export const useUploadOneFile = () => {
  const dispatch: AppDispatch = useDispatch();

  const onUpload = React.useCallback(
    (f: File | string) => {
      if (typeof f === "string") {
        return Promise.resolve(f);
      }
      return dispatch(uploadOneFile(f));
    },
    [dispatch]
  );

  return [onUpload] as [typeof onUpload];
};

export const useGetSignedDownloadURL = () => {
  const dispatch: AppDispatch = useDispatch();
  const [setting, setLocalConfig] = useExpiringLocalConfig(
    SettingsKeys.RouterQueryString
  );

  const onGetURL = React.useCallback(
    (key: string, data: downloadUrlParams) => {
      return new Promise<string>((resolve, reject) => {
        const url = setting?.[key];
        if (url) {
          resolve(url);
          return;
        }
        dispatch(getSignedDownloadURL(data))
          .then((r) => {
            setLocalConfig({ ...setting, [key]: r.url }, moment(r.expiresAt));
            resolve(r.url);
          })
          .catch(reject);
      });
    },
    [setting, setLocalConfig, dispatch]
  );

  return [onGetURL] as [typeof onGetURL];
};
