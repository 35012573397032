import React from "react";
import { connect } from "react-redux";
import { getUserMe, savePrivateFile } from "ducks/user";
import Recorder from "@udok/lib/components/Audio/Recorder";
import { PrivateFileUploadedResponse } from "@udok/lib/api/user";
import { millisToMinAndSec } from "@udok/lib/internal/util";

const Composer = (props: {
  userID: string;
  currentUserID?: string;
  onAudio: (fileID: string, name: string, duration: string) => void;
  savePrivateFile: (
    file: File,
    name: string,
    permittedUser: string
  ) => Promise<PrivateFileUploadedResponse>;
  [k: string]: any;
}) => {
  const { userID, savePrivateFile, onAudio } = props;
  const [loading, setLoading] = React.useState(false);

  const handleSubmitRecording = (buffer: ArrayBuffer, blob: Blob) => {
    const file = new File(buffer as any, "audio.mp3", {
      type: blob.type,
      lastModified: Date.now(),
    });
    const player = new Audio(URL.createObjectURL(file));
    player.addEventListener("loadeddata", () => {
      let duration = millisToMinAndSec(player.duration);
      setLoading(true);
      savePrivateFile(file, file.name, userID)
        .then((file) => {
          onAudio(file.fileID, file.name, duration);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Recorder onRecordingFinished={handleSubmitRecording} loading={loading} />
  );
};

export default connect(getUserMe, (dispatch: any) => ({
  savePrivateFile: (file: File, name: string, permittedUser: string) =>
    dispatch(savePrivateFile(file, name, permittedUser)),
}))(Composer);
