import { toQueryString } from "@udok/lib/internal/util";
import {
  TaxInvoiceView,
  TaxInvoiceFilter,
  TaxInvoiceSettingsView,
  TaxInvoiceSettingsForm,
  TaxInvoiceServiceCode,
  TaxInvoiceServiceCodeForm,
  TaxInvoiceServiceCodeFilter,
} from "@udok/lib/api/models";
import {
  makeItemFetch,
  makeItemsFetch,
  makeItemPost,
  makeItemDelete,
} from "@udok/lib/api/axios";

export const createTaxInvoice = async (token: string, invoID: string) =>
  makeItemPost<TaxInvoiceView, { invoID: string }>({
    url: `/api/tax-invoice`,
    errLabel: "TI1",
    token,
    data: { invoID },
  });

export const cancelTaxInvoice = async (token: string, tainID: string) =>
  makeItemDelete<TaxInvoiceView>({
    url: `/api/tax-invoice/${tainID}`,
    errLabel: "TI2",
    token,
  });

export const getTaxInvoice = (token: string, tainID: string) =>
  makeItemFetch<TaxInvoiceView>({
    url: `/api/tax-invoice/${tainID}`,
    errLabel: "TI3",
    token,
  });

export const listImmediateCare = (token: string, f?: TaxInvoiceFilter) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<TaxInvoiceView[]>({
    url: `/api/tax-invoice${filter}`,
    errLabel: "TI4",
    token,
  });
};

export const getTaxInvoiceSettings = (token: string) =>
  makeItemFetch<TaxInvoiceSettingsView>({
    url: `/api/tax-invoice/settings`,
    errLabel: "TI5",
    token,
  });

export const createTaxInvoiceSettings = async (
  token: string,
  data: TaxInvoiceSettingsForm
) =>
  makeItemPost<TaxInvoiceSettingsView, TaxInvoiceSettingsForm>({
    url: `/api/tax-invoice/settings`,
    errLabel: "TI6",
    token,
    data,
  });

export const createTaxInvoiceServiceCode = async (
  token: string,
  data: TaxInvoiceServiceCodeForm
) =>
  makeItemPost<TaxInvoiceServiceCode, TaxInvoiceServiceCodeForm>({
    url: `/api/tax-invoice/service-code`,
    errLabel: "TI7",
    token,
    data,
  });

export const listTaxInvoiceServiceCode = (
  token: string,
  f?: TaxInvoiceServiceCodeFilter
) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<TaxInvoiceServiceCode[]>({
    url: `/api/tax-invoice/service-code${filter}`,
    errLabel: "TI8",
    token,
  });
};

export const deleteTaxInvoiceServiceCode = async (
  token: string,
  tiscID: number
) =>
  makeItemDelete<TaxInvoiceServiceCode>({
    url: `/api/tax-invoice/service-code/${tiscID}`,
    errLabel: "TI9",
    token,
  });
