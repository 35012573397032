import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  MedicalHistoryTemplate,
  MedicalHistoryTemplateForm,
  FilterMedicalHistoryTemplate,
} from "@udok/lib/api/models";
import {
  medicalHistoryTemplates,
  createMedicalHistoryTemplate,
  updateMedicalHistoryTemplate,
  getMedicalHistoryTemplate,
  deleteMedicalHistoryTemplate,
} from "@udok/lib/api/medicalHistory";
import { getToken, UNAUTHORIZED } from "./auth";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  medicalHistoryTemplatesByID: {
    [doteID: string]: MedicalHistoryTemplate | undefined;
  };
};

// Reducers
const initialState: InitialState = {
  medicalHistoryTemplatesByID: {},
};

class MedicalHistory extends Hen<InitialState> {
  medicalHistoryTemplateLoaded(temp: MedicalHistoryTemplate) {
    this.state.medicalHistoryTemplatesByID[temp?.doteID ?? ""] = temp;
  }
  medicalHistoryTemplatesLoaded(temps: MedicalHistoryTemplate[]) {
    temps.forEach((temp) => {
      this.state.medicalHistoryTemplatesByID[temp?.doteID ?? ""] = temp;
    });
  }
  scheduleLockRemoved(temp: MedicalHistoryTemplate) {
    delete this.state.medicalHistoryTemplatesByID[temp?.doteID ?? ""];
  }
}

export const [Reducer, actions] = hen(new MedicalHistory(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.medicalHistory;

export const getMedicalHistoryTemplateByID = (props: { doteID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      template: state.medicalHistoryTemplatesByID?.[props.doteID],
    };
  });

export const getMedicalHistoryTemplates = createSelector(
  mainSelector,
  (state) => {
    return {
      list: Object.keys(state.medicalHistoryTemplatesByID)
        .map((doteID) => state.medicalHistoryTemplatesByID[doteID])
        .sort((a, b) => moment(b?.createdAt).diff(moment(a?.createdAt)))
        .filter((temp) => !!temp) as MedicalHistoryTemplate[],
    };
  }
);

// Actions
export function createOneMedicalHistoryTemplate(
  form: MedicalHistoryTemplateForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createMedicalHistoryTemplate(apiToken, form)
      .then((r) => {
        dispatch(actions.medicalHistoryTemplateLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Criado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadMedicalHistoryTemplates(
  filter?: FilterMedicalHistoryTemplate
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return medicalHistoryTemplates(apiToken, filter)
      .then((r) => {
        dispatch(actions.medicalHistoryTemplatesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateOneMedicalHistoryTemplate(
  doteID: string,
  template: MedicalHistoryTemplateForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return updateMedicalHistoryTemplate(apiToken, doteID, template)
      .then((r) => {
        dispatch(actions.medicalHistoryTemplateLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Atualizado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getOneMedicalHistoryTemplate(
  doteID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return getMedicalHistoryTemplate(apiToken, doteID)
      .then((r) => {
        dispatch(actions.medicalHistoryTemplateLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedMedicalHistoryTemplate(
  doteID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const exit = state.medicalHistory.medicalHistoryTemplatesByID[doteID];
    if (exit) {
      return Promise.resolve();
    }
    return dispatch(getOneMedicalHistoryTemplate(doteID));
  };
}

export function removeMedicalHistoryTemplate(
  doteID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteMedicalHistoryTemplate(apiToken, doteID)
      .then((r) => {
        dispatch(actions.scheduleLockRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
