import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Typography, FormHelperText } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldset: {
      margin: 0,
      display: "grid",
      padding: theme.spacing(0, 1),
      overflow: "hidden",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: theme.spacing(1),
      color: "#4f5555",
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    label: {
      fontSize: "0.95em",
      transform: "scale(0.98)",
    },
    labelError: {
      color: theme.palette.error.main,
    },
    fieldsetError: {
      borderColor: theme.palette.error.main,
      borderWidth: 1.5,
    },
    helperText: {
      margin: theme.spacing(0, 1.75),
    },
    marginNormal: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    marginDense: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
  })
);

export type CustomFieldsetProps = {
  label?: React.ReactNode;
  required?: boolean;
  margin?: "none" | "normal" | "dense";
  error?: boolean;
  errorMessage?: string;
  children: React.ReactNode;
  className?: string;
  classes?: {
    fieldset?: string;
    label?: string;
    helperText?: string;
  };
};

const CustomFieldset = React.forwardRef(
  (props: CustomFieldsetProps, ref: React.Ref<any>) => {
    const {
      label,
      margin,
      error,
      errorMessage,
      className,
      classes: classesProps,
      children,
      required = false,
    } = props;
    const classes = useStyles();
    return (
      <>
        <fieldset
          ref={ref}
          className={clsx(classes.fieldset, className, classesProps?.fieldset, {
            [classes.fieldsetError]: error,
            [classes.marginNormal]: margin === "normal",
            [classes.marginDense]: margin === "dense",
          })}
        >
          {label ? (
            <Typography
              variant="subtitle1"
              component="legend"
              color="primary"
              className={clsx(classes.label, classesProps?.label, {
                [classes.labelError]: error,
              })}
            >
              {label}
              {required ? <span>&nbsp;*</span> : null}
            </Typography>
          ) : null}
          {children}
        </fieldset>
        <FormHelperText
          error={error}
          className={clsx(classes.helperText, classesProps?.helperText)}
        >
          {errorMessage}
        </FormHelperText>
      </>
    );
  }
);

export default CustomFieldset;
