import React from "react";
import { QuickResponse } from "@udok/lib/api/models";
import { useSearchQuickResponse } from "hooks/messaging";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
} from "@material-ui/core";
import Dialog from "@udok/lib/components/Dialog/ResponsiveDialog";
import ListSearchInput from "@udok/lib/components/Input/ListSearchInput";
import Icons from "@udok/lib/components/Icon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    toolbar: {
      background: theme.palette.primary.light,
    },
    notfound: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    list: {
      height: 500,
      overflowY: "auto",
      paddingLeft: 0,
      paddingRight: 0,
    },
    closeBtn: { color: "#fff", marginRight: 12 },
    inline: {
      display: "inline",
    },
  })
);

export interface ModalSearchProps {
  onSelect: (q: QuickResponse) => void;
  onDismiss: () => void;
  open: boolean;
}
const ModalSearch = (props: ModalSearchProps) => {
  const { open, onSelect, onDismiss } = props;
  const classes = useStyles();
  const placeholder = "Busca por respostas rápidas";
  const [loading, search, options] = useSearchQuickResponse();

  React.useEffect(() => {
    if (open) {
      search();
    }
  }, [open, search]);

  const doSearch = React.useCallback(
    (str: string) => {
      let k = str.toLocaleLowerCase();
      search({ searchText: k });
    },
    [search]
  );
  const noResults = !loading && options.length === 0;

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      aria-labelledby="serch-quickresponses"
      className={classes.root}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <ListSearchInput
          onChange={doSearch}
          loading={loading}
          component="div"
          autoFocus
          placeholder={placeholder}
        />
        <IconButton onClick={onDismiss} className={classes.closeBtn}>
          <Icons.Close />
        </IconButton>
      </Toolbar>
      <DialogTitle id="serch-quickresponses" style={{ display: "none" }}>
        {placeholder}
      </DialogTitle>
      <DialogContent className={classes.list}>
        {noResults ? (
          <div className={classes.notfound}>
            <DialogContentText>
              Não há resultados para o termo buscado
            </DialogContentText>
          </div>
        ) : null}
        <List>
          {options.map((o) => (
            <ListItem button onClick={() => onSelect(o)} key={o.quickID}>
              <ListItemText
                primary={o.title}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {o.keywords.join(", ")}
                    </Typography>{" "}
                    <span>— {o.value}</span>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Icons.Send color="action" />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSearch;
