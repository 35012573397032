import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  ClinicDoctor,
  Doctor,
  ClinicDoctorListFilter,
  DoctorClinicPatient,
} from "@udok/lib/api/models";
import {
  FilterDoctorList,
  fetchDoctors,
  deleteDoctorFromClinic,
  AddDoctorToClinicRequest,
  addDoctorToClinic,
  fetcClinicDoctors,
  fetchClinicDoctor,
  addClinicDoctorPatient,
  fetchClinicDoctorPatients,
} from "@udok/lib/api/doctor";
import { getToken, UNAUTHORIZED } from "./auth";
import { createClinicSpecialty } from "@udok/lib/api/specialty";
import { clinicSpecialtyListView } from "ducks/clinic";
import { actions as scheduleActions } from "ducks/schedule";
import { actions as procedureActions } from "ducks/procedure";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  allDoctors: Doctor[];
  doctorByUserID: { [userID: string]: ClinicDoctor | undefined };
  clinicDoctorByID: { [doctID: string]: ClinicDoctor | undefined };
  clinicDoctorPatients: DoctorClinicPatient[];
};

// Reducers
const initialState: InitialState = {
  doctorByUserID: {},
  allDoctors: [],
  clinicDoctorByID: {},
  clinicDoctorPatients: [],
};

class DoctorSlice extends Hen<InitialState> {
  allDoctorsLoaded(v: Doctor[]) {
    this.state.allDoctors = v;
  }

  clinicDoctorLoaded(doc: ClinicDoctor) {
    this.state.clinicDoctorByID[doc.doctID] = doc;
    this.state.doctorByUserID[doc.userID] = doc;
  }

  listClinicDoctorsLoaded(cd: ClinicDoctor[]) {
    cd.forEach((doc) => {
      this.state.clinicDoctorByID[doc.doctID] = doc;
      this.state.doctorByUserID[doc.userID] = doc;
    });
  }
  clinicDoctorPatientLoaded(cdp: DoctorClinicPatient) {
    let newList = [...this.state.clinicDoctorPatients];
    let index = this.state.clinicDoctorPatients.findIndex(
      (d) => d.patiID === cdp.patiID
    );
    if (index === -1) {
      newList = [...newList, cdp];
    } else {
      newList[index] = cdp;
    }
    this.state.clinicDoctorPatients = newList;
  }
  clinicDoctorPatientsLoaded(cdps: DoctorClinicPatient[]) {
    this.state.clinicDoctorPatients = cdps;
  }
}

export const [Reducer, actions] = hen(new DoctorSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.doctor;
export const doctorRepositoryByUserID = (state: RootState) =>
  mainSelector(state).doctorByUserID;
export const allDoctorRepository = (state: RootState) =>
  mainSelector(state).allDoctors;
export const clinicDoctorRepository = (state: RootState) =>
  mainSelector(state).clinicDoctorByID;
export const clinicDoctorPatientsRepository = (state: RootState) =>
  mainSelector(state).clinicDoctorPatients;

export type DoctorListView = ClinicDoctor;
export const clinicDoctorListView = createSelector(
  clinicDoctorRepository,
  (clinicDoctorByID) => {
    return {
      list: Object.keys(clinicDoctorByID)
        .map((doctID) => {
          return clinicDoctorByID[doctID];
        })
        .sort((a, b) => (a?.displayOrder ?? 0) - (b?.displayOrder ?? 0))
        .filter((d) => !!d && !d?.deletedAt) as ClinicDoctor[],
    };
  }
);

export const createDoctorView = createSelector(
  [allDoctorRepository, clinicSpecialtyListView],
  (allDoctors, specialties) => {
    return {
      listDoctors: allDoctors,
      listclinicSpec: specialties.list,
    };
  }
);

export const doctorView = (state: RootState, props: { doctID: string }) =>
  createSelector(clinicDoctorRepository, (clinicDoctorByID) => {
    return {
      doctor: clinicDoctorByID[props.doctID],
    };
  });

export const getOneDoctorByID = (props: { doctID: string }) =>
  createSelector(clinicDoctorRepository, (clinicDoctorByID) => {
    return {
      doctor: clinicDoctorByID[props.doctID],
    };
  });

export const getClinicDoctorByID = createSelector(
  clinicDoctorRepository,
  (clinicDoctorByID) => {
    return {
      clinicDoctorByID,
    };
  }
);

export const clinicDoctorList = createSelector(
  clinicDoctorRepository,
  (clinicDoctorByID) => {
    return {
      list: Object.keys(clinicDoctorByID).map(
        (doctID) => clinicDoctorByID[doctID]
      ),
    };
  }
);

export const getDoctorlinkedPatient = (
  state: RootState,
  props: { patiID: string }
) =>
  createSelector(
    [clinicDoctorPatientsRepository, clinicDoctorRepository],
    (links, doctors) => {
      const link = links.filter((l) => l.patiID === props.patiID)?.[0];
      const doctor = doctors[link?.doctID ?? ""];
      return {
        doctor,
      };
    }
  );

// Actions
export function loadAllDoctors(
  f?: FilterDoctorList
): AppThunk<Promise<Doctor[]>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const filter = { ...f, listAll: true };
    return fetchDoctors(apiToken, filter)
      .then((r) => {
        dispatch(actions.allDoctorsLoaded(r));
        return r;
      })
      .catch((e) => {
        console.warn(e);
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        return e;
      });
  };
}

export function loadOneDoctor(doctID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchClinicDoctor(apiToken, doctID)
      .then((r) => {
        dispatch(actions.clinicDoctorLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadCachedDoctor(doctID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const doctorExist = Boolean(state.doctor.clinicDoctorByID[doctID]);
    if (doctorExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneDoctor(doctID));
  };
}

export function addOneDoctor(
  doctor: AddDoctorToClinicRequest
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return addDoctorToClinic(apiToken, doctor)
      .then(async (r) => {
        dispatch(actions.clinicDoctorLoaded(r));
        if (doctor.specialties) {
          Promise.all(
            doctor.specialties.map((specID: number) =>
              createClinicSpecialty(apiToken, { specID, order: 10 })
            )
          );
        }
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Profissional de saúde adicionado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeOneDoctor(doctID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteDoctorFromClinic(apiToken, doctID)
      .then((r) => {
        dispatch(actions.clinicDoctorLoaded(r));
        dispatch(scheduleActions.removeSchedulesByUserID(r.userID));
        dispatch(procedureActions.removeDoctorFromExamProcedure(r.doctID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadClinicDoctors(
  f?: ClinicDoctorListFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetcClinicDoctors(apiToken, f)
      .then((r) => {
        dispatch(actions.listClinicDoctorsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function addOneClinicDoctorPatient(data: {
  doctID: string;
  patiID: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return addClinicDoctorPatient(apiToken, data)
      .then((r) => {
        dispatch(actions.clinicDoctorPatientLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadClinicDoctorPatients(filter?: {
  doctID?: string;
  patiID?: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchClinicDoctorPatients(apiToken, filter)
      .then((r) => {
        dispatch(actions.clinicDoctorPatientsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
