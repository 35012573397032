import React from "react";
import { useGetClientsStatus } from "hooks/socket";
import UserStatusView from "@udok/lib/components/UserStatus";

export type UserStatusProps = {
  userID: string;
};

const UserStatus = (props: UserStatusProps) => {
  const { userID } = props;
  const [status, src, name] = useGetClientsStatus(userID);

  return <UserStatusView status={Boolean(status)} src={src} name={name} />;
};

export default UserStatus;
