import React from "react";
import { connect } from "react-redux";
import { downloadFileBlob } from "ducks/files";
import { AppDispatch } from "ducks/state";
import {
  IconButton,
  IconButtonProps,
  CircularProgress,
} from "@material-ui/core";

export type FileDownloaderProps<C extends React.ElementType> = {
  component?: C;
} & {
  fileID: string;
  filleName?: string;
} & ReturnType<typeof mapDispatchToProps> &
  Omit<React.ComponentProps<C>, "options">;

const FileDownloader = <C extends React.ElementType>(
  props: FileDownloaderProps<C>
) => {
  const { fileID, downloadFile, filleName, component, ...other } = props;
  const [loading, setL] = React.useState(false);
  const reload = React.useCallback(() => {
    if (fileID && filleName) {
      setL(true);
      downloadFile(fileID, filleName).finally(() => {
        setL(false);
      });
    }
  }, [fileID, filleName, downloadFile]);

  const Btn = component ?? LoadingIconButton;

  return <Btn onClick={reload} loading={loading} {...other} />;
};

const LoadingIconButton = (props: { loading?: boolean } & IconButtonProps) => (
  <IconButton
    {...props}
    children={props.loading ? <CircularProgress /> : props.children}
    disabled={props.disabled || props.loading}
  />
);

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  downloadFile: (fileID: string, filleName: string) =>
    dispatch(downloadFileBlob(fileID, filleName)),
});

export default connect(() => ({}), mapDispatchToProps)(FileDownloader);
