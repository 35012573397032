import {
  Schedule,
  ScheduleSlot,
  ExamProcedure,
  ClinicDoctor,
  SearchSchedule,
  ScheduleType,
  ScheduleCalendar,
  ScheduleLockView,
  ScheduleLockOrigin,
  ScheduleLockType,
  Appointment,
  AppointmentStatus,
  EventsPerDay,
  EventData,
  SearchAppointmentsFilter,
} from "@udok/lib/api/models";
import { format, compareStringArrays } from "@udok/lib/internal/util";
import Icons from "@udok/lib/components/Icon";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");

export function getWeekDay(date: string, dateFormat: string) {
  moment.locale("en-us");
  const weekDay = moment(date, dateFormat).format(format.FULLDAY).toLowerCase();
  moment.locale("pt-br");
  return weekDay;
}

export function calculateScheduleListByDay(
  schedules: Schedule[],
  date: string
) {
  const newList = schedules.filter((s) => {
    const mDate = moment(date, format.DASHUN);
    const isWeekday = s.weekDay?.length > 0;
    const isBetween = moment(mDate).isBetween(s.startAt, s.endAt, "day", "[]");
    if (!isBetween) {
      return false;
    }
    if (isWeekday) {
      const timezoneDate = moment
        .utc(
          `${mDate.format(format.DASHUN)}T${moment
            .utc(s.startAt)
            .format(format.FULLTIME24H)}`
        )
        .utcOffset((s?.weekdayTimezoneOffset ?? 0) / 60);

      if (timezoneDate.isBefore(mDate, "day")) {
        timezoneDate.add(1, "day");
        mDate.add(1, "day");
      }

      const dow = getWeekDay(timezoneDate.format(format.DASHUN), format.DASHUN);
      const inWeekday = s.weekDay?.indexOf(dow) > -1;
      if (inWeekday) {
        return true;
      }
      const nextDay = moment(mDate).subtract(1, "day").format(format.DASHUN);
      const yesterday = moment
        .utc(`${nextDay}T${moment.utc(s.startAt).format(format.FULLTIME24H)}`)
        .utcOffset((s?.weekdayTimezoneOffset ?? 0) / 60);
      const previousDow = getWeekDay(
        yesterday.format(format.DASHUN),
        format.DASHUN
      );
      if (s.weekDay?.indexOf(previousDow) === -1) {
        return false;
      }
      const startAt = yesterday.clone().local();
      const startAtFromYesterday = moment(startAt).add(
        s.eventDuration - s.appointmentDuration,
        "minutes"
      );
      return startAtFromYesterday.format(format.DASHUN) === date;
    }
    const startAt = moment(s.startAt).local();
    const starlastSlot = moment(startAt).add(
      s.eventDuration - s.appointmentDuration,
      "minutes"
    );

    return (
      startAt.format(format.DASHUN) === date ||
      starlastSlot.format(format.DASHUN) === date
    );
  });

  return newList;
}

export type scheduleSlotsByDay = {
  scheduleInfo: {
    type: string;
    clinID?: string;
    doctID?: string;
    locaID?: string;
    price: number;
    marketplaceOffer: boolean;
    exprIDs?: string[];
    defaultStatus?: string;
    healthPlans?: string[];
  };
  slots: { sescID: string; slot: moment.Moment }[];
};

function groupSchedules(schedules: (SearchSchedule & { doctID?: string })[]) {
  let group: (scheduleSlotsByDay["scheduleInfo"] & {
    calendar: { [sescID: string]: ScheduleCalendar[] };
  })[] = [];

  schedules.forEach((sch) => {
    const groupIndex = group.findIndex(
      (g) =>
        g?.clinID === sch?.clinID &&
        g?.type === sch?.type &&
        g?.locaID === sch?.locaID &&
        g?.price === sch.price &&
        g?.marketplaceOffer === sch.marketplaceOffer &&
        compareStringArrays(g?.exprIDs ?? [], sch?.procedures ?? [])
    );
    if (groupIndex === -1) {
      group = [
        ...group,
        {
          clinID: sch?.clinID,
          type: sch.type,
          locaID: sch?.locaID,
          exprIDs: sch?.procedures,
          price: sch?.price,
          marketplaceOffer: sch?.marketplaceOffer,
          doctID: sch?.doctID,
          defaultStatus: sch.defaultStatus,
          healthPlans: sch.healthPlans,
          calendar: {
            [sch.sescID]: sch.scheduleCalendar,
          },
        },
      ];
      return;
    }
    let g = { ...group[groupIndex] };
    g.calendar[sch.sescID] = sch.scheduleCalendar;
    group[groupIndex] = g;
  });

  return group;
}

export function calculateScheduleSlotsByDay(
  schedules: (SearchSchedule & { doctID?: string })[],
  filter: SearchAppointmentsFilter
) {
  const filtredCalendar: { [day: string]: scheduleSlotsByDay[] } = {};
  let validCalendarDays: string[] = [];
  const groupedSchedules = groupSchedules(schedules);

  groupedSchedules.forEach((sch) => {
    const validSchedule =
      (sch.healthPlans ?? []).filter((heplID) => {
        return (filter?.healthplans ?? []).indexOf(heplID) > -1;
      }).length > 0;

    let slotsPerDay: {
      [day: string]: { sescID: string; slotUTC: moment.Moment }[] | undefined;
    } = {};
    Object.keys(sch.calendar).map((sescID) => {
      const listCalendar = sch.calendar[sescID];
      listCalendar.forEach((calendar) => {
        const start = moment(calendar.firstSlot).local();
        const slotsUTC = [...calendar.slots]
          .map((d) => moment.utc(d))
          .filter((d) => start.isSame(d, "day"))
          .sort((a, b) => a.diff(b));
        if (!start.isBefore(moment(), "day")) {
          const current = slotsPerDay[start.format(format.DASHUN)];
          const newSlots = slotsUTC.map((slotUTC) => ({ sescID, slotUTC }));

          if (!!current) {
            slotsPerDay[start.format(format.DASHUN)] = [
              ...current,
              ...newSlots.filter(
                (n) =>
                  current.findIndex((cur) => cur.slotUTC.isSame(n.slotUTC)) ===
                  -1
              ),
            ];
          } else {
            slotsPerDay[start.format(format.DASHUN)] = newSlots;
          }
        }
        const end = moment(calendar.lastSlot).local();
        if (!end.isSame(start, "day")) {
          const slotsUTC = [...calendar.slots]
            .map((d) => moment.utc(d))
            .filter((d) => end.isSame(d, "day"))
            .sort((a, b) => a.diff(b));
          const current = slotsPerDay[end.format(format.DASHUN)];
          const newSlots = slotsUTC.map((slotUTC) => ({ sescID, slotUTC }));
          if (!!current) {
            slotsPerDay[end.format(format.DASHUN)] = [
              ...current,
              ...newSlots.filter(
                (n) =>
                  current.findIndex((cur) => cur.slotUTC.isSame(n.slotUTC)) ===
                  -1
              ),
            ];
          } else {
            slotsPerDay[end.format(format.DASHUN)] = newSlots;
          }
        }
      });
    });

    const scheduleInfo: scheduleSlotsByDay["scheduleInfo"] = {
      clinID: sch?.clinID,
      type: sch.type,
      locaID: sch?.locaID,
      exprIDs: sch?.exprIDs,
      price: sch.price,
      marketplaceOffer: sch.marketplaceOffer,
      doctID: sch?.doctID,
      defaultStatus: sch?.defaultStatus,
      healthPlans: sch?.healthPlans,
    };
    Object.keys(slotsPerDay).forEach((day) => {
      if (validSchedule) {
        validCalendarDays = [...validCalendarDays, day];
      }
      const slots = (slotsPerDay[day] ?? [])
        .map((s) => ({ sescID: s.sescID, slot: s.slotUTC.local() }))
        .sort((a, b) => a.slot.diff(b.slot));
      if (slots.length > 0) {
        const calendar: scheduleSlotsByDay = {
          scheduleInfo,
          slots,
        };
        filtredCalendar[day] = [
          ...(filtredCalendar?.[day] ?? []),
          calendar,
        ].sort((a, b) => a?.slots?.[0]?.slot?.diff?.(b?.slots?.[0]?.slot));
      }
    });
  });

  const [startDate, endDate] = Object.keys(filtredCalendar ?? {}).reduce(
    (a, b) => [b < a[0] ? b : a[0], b > a[1] ? b : a[1]],
    ["9", "1"]
  );
  const [defaultDate] = validCalendarDays.reduce(
    (a, b) => [b < a[0] ? b : a[0], b > a[1] ? b : a[1]],
    ["9", "1"]
  );

  return {
    filtredCalendar,
    startDate,
    endDate,
    defaultDate,
  };
}

export type ScheduleView = Schedule & {
  doctID?: string;
  clearancePeriod?: number;
};
export function calculateScheduleLayoutInformation(
  schedule: ScheduleView,
  procedures: ExamProcedure[],
  doctor?: ClinicDoctor,
  filter?: any
) {
  const pro = procedures.find(
    (p) => (schedule?.procedures ?? []).indexOf(p.exprID) !== -1
  );
  const procedureName = pro?.title ?? "Exames/Procedimentos";
  const locationName = schedule?.localeInfo?.name ?? "Presencial";
  const locationAddress = schedule?.localeInfo?.address ?? "";
  const clinicName = schedule?.clinicInfo?.name ?? "Clínica";
  const doctorName = doctor?.doctName ?? "Profissional de saúde";
  const specialties = (doctor?.specialty ?? [])
    .filter((spe) =>
      filter?.specID ? String(spe.specID) === filter.specID : true
    )
    .map((spe) => spe.nameSpecialty)
    .join(", ");
  const isSmallerScreen = window.innerWidth <= 600;
  const characterLimit = 25;

  if (procedures.length > 0 && schedule.type === ScheduleType.examProcedure) {
    let secondary = locationAddress;
    if (!doctor && schedule?.clinID && !isSmallerScreen) {
      let name = clinicName;
      if (clinicName.length > characterLimit) {
        name = clinicName.substring(0, characterLimit) + "...";
      }
      secondary = name + " - " + locationAddress;
    }
    if (
      !!doctor &&
      !doctor?.preferences?.hideProfileInProcedure &&
      !isSmallerScreen
    ) {
      let name = doctorName;
      if (doctorName.length > characterLimit) {
        name = doctorName.substring(0, characterLimit) + "...";
      }
      secondary = name + " - " + locationAddress;
    }
    return {
      primary: procedureName,
      secondary: secondary,
    };
  }
  if (!!schedule?.locaID) {
    let primary = locationName;
    if (!!schedule?.clinID) {
      primary = clinicName;
    }
    if (!!doctor) {
      primary = doctorName;
    }
    return {
      primary: primary,
      secondary: [
        ...(specialties.length > 0 ? [specialties] : []),
        ...(locationAddress.length > 0 ? [locationAddress] : []),
      ].join(" - "),
    };
  }
  let primary = "Telemedicina";
  let secondary = "";
  if (!!schedule?.clinID) {
    primary = clinicName;
    secondary = "Telemedicina";
  }
  if (!!doctor) {
    primary = doctorName;
    secondary = specialties;
  }
  return {
    primary: primary,
    secondary: secondary,
  };
}

export function scheduleSlotLayoutInformation(
  sch: ScheduleSlot,
  clinicLayout: boolean
) {
  const clinicName = sch?.clinicName ?? "Clínica";
  const doctorName = sch?.doctorName ?? "Profissional de saúde";
  const isSmallerScreen = window.innerWidth <= 600;
  const characterLimit = 25;

  if (sch.type === ScheduleType.examProcedure) {
    let secondary = sch.locationAddress;
    if (!clinicLayout && sch?.clinID && !isSmallerScreen) {
      let name = clinicName;
      if (name.length > characterLimit) {
        name = name.substring(0, characterLimit) + "...";
      }
      secondary = name + " - " + sch.locationAddress;
    }
    if (
      clinicLayout &&
      !sch.displayPreferences.hideProfileInProcedure &&
      !isSmallerScreen
    ) {
      let name = doctorName;
      if (doctorName.length > characterLimit) {
        name = doctorName.substring(0, characterLimit) + "...";
      }
      secondary = name + " - " + sch.locationAddress;
    }
    return {
      primary: sch.examProcedureText,
      secondary: secondary,
    };
  }

  let primary = sch?.clinID ? clinicName : sch.locationName;
  let secondary = sch.locationAddress;
  if (clinicLayout) {
    primary = doctorName;
    secondary = [sch.specialtyText, sch.locationAddress].join(" - ");
  }

  return {
    primary: primary,
    secondary: secondary,
  };
}

export function getScheduleIcon(n: ScheduleType) {
  const scheduleIcons = {
    [ScheduleType.virtual]: "Videocam" as keyof typeof Icons,
    [ScheduleType.place]: "LocationOn" as keyof typeof Icons,
    [ScheduleType.examProcedure]: "Stethoscope" as keyof typeof Icons,
  };
  return scheduleIcons?.[n] ?? ("LocationOn" as keyof typeof Icons);
}

export function getScheduleIconTitle(n: ScheduleType) {
  let title = {
    [ScheduleType.examProcedure]: "Exame/Procedimento",
    [ScheduleType.virtual]: "Telemedicina",
    [ScheduleType.place]: "Consulta presencial",
  };
  return title?.[n] ?? "Consulta presencial";
}

export type TimeLock = {
  id: string;
  origin: ScheduleLockOrigin;
};

export function blockFilter(
  b: ScheduleLockView,
  sch: {
    sescID?: string;
    doctID?: string;
    clinID?: string;
    type?: string;
    simultaneousAppointments?: number;
    sharedResources?: string[];
  }
) {
  const isValidType = b.type === ScheduleLockType.all || b.type === sch.type;
  let isValidDoctor = true;
  let isValidClinic = true;
  let isValidSchedule = true;
  let isValidResource = true;

  if (!!sch?.doctID) {
    isValidDoctor = !b?.doctID || b?.doctID === sch.doctID;
  }
  if (
    !!b?.sescID ||
    ((sch?.simultaneousAppointments ?? 1) > 1 &&
      b.origin === ScheduleLockOrigin.appointment)
  ) {
    isValidSchedule = b?.sescID === sch?.sescID;
  }
  if (!!b?.clinID) {
    isValidClinic = b.clinID === sch?.clinID;
  }

  if (b.origin === ScheduleLockOrigin.sharedResource) {
    isValidResource = (sch?.sharedResources ?? []).indexOf(b.id) !== -1;
  }

  return (
    isValidType &&
    isValidDoctor &&
    isValidSchedule &&
    isValidClinic &&
    isValidResource
  );
}

export function scheduleTimesByDuration(
  schedules: (Schedule & { doctID?: string })[],
  resourceID: string,
  date: string, //the date must be in the DASHUN format
  blockedHours?: ScheduleLockView[],
  scheduleAppointments?: {
    [sescID: string]: { appoID: string; markedAt: string }[];
  }
) {
  moment.locale("pt-br");
  const item = schedules.find((element) => element.sescID === resourceID);
  let arr = [] as {
    label: string;
    value: string;
    lockIDs: TimeLock[];
    occupiedSlots?: number;
    maxSlots?: number;
  }[];
  if (!item) {
    return arr;
  }
  const appointments = scheduleAppointments?.[item.sescID] ?? [];
  const { start } = formatShedulePeriod(
    item.startAt,
    item.eventDuration,
    item?.weekDay ?? [],
    date,
    item?.weekdayTimezoneOffset
  );
  const momentDate = moment(date, format.DASHUN);
  let minutes = item.eventDuration;
  const duration = item.appointmentDuration;
  const qnt = Math.floor(minutes / duration);
  for (let index = 0; index < qnt; index++) {
    const startOf = start.clone().add(index * duration, "minutes");
    const endOf = start.clone().add(index * duration + duration, "minutes");
    if (!startOf.isSame(momentDate, "day")) {
      continue;
    }

    let lockIDs: TimeLock[] = [];
    const availableBlocks = (blockedHours ?? []).filter((b) =>
      blockFilter(b, {
        doctID: item.doctID,
        clinID: item.clinID,
        sescID: item.sescID,
        sharedResources: item.sharedResources,
        simultaneousAppointments: item.simultaneousAppointments,
      })
    );
    if (availableBlocks.length > 0) {
      availableBlocks.forEach((block) => {
        const blockStart = moment.utc(block?.startAt).local();
        const blockEnd = moment.utc(block?.endAt).local();
        const inRenge =
          startOf.isBetween(blockStart, blockEnd, undefined, "[)") ||
          endOf.isBetween(blockStart, blockEnd, undefined, "()");
        if (inRenge) {
          lockIDs = [...lockIDs, { id: block.id, origin: block.origin }];
        }
      });
    }

    let occupiedSlots: number | undefined;
    let maxSlots: number | undefined;
    if ((item?.simultaneousAppointments ?? 1) > 1) {
      maxSlots = item?.simultaneousAppointments;
      occupiedSlots = appointments.filter((app) =>
        startOf.isSame(app.markedAt)
      ).length;
    }

    arr = [
      ...arr,
      {
        label: `${startOf.format("HH:mm")} - ${endOf.format("HH:mm")}`,
        value: startOf.local().format(),
        lockIDs,
        maxSlots,
        occupiedSlots,
      },
    ];
  }
  return arr;
}

export function formatShedulePeriod(
  startAt: string,
  duration: number,
  weekDay: string[],
  date: string,
  weekdayTimezoneOffset?: number
) {
  const currentDate = moment(date, format.DASHUN);
  const isWeekday = weekDay?.length > 0;

  let start = moment(startAt).local();
  if (isWeekday) {
    const startHour = moment.utc(startAt).format(format.FULLTIME24H);
    moment.locale("en-us");
    const timezoneDate = moment
      .utc(`${currentDate.format(format.DASHUN)}T${startHour}`)
      .utcOffset((weekdayTimezoneOffset ?? 0) / 60);
    if (timezoneDate.isBefore(currentDate, "day")) {
      timezoneDate.add(1, "day");
      currentDate.add(1, "day");
    }
    const dow = timezoneDate.format(format.FULLDAY).toLowerCase();
    moment.locale("pt-br");
    const inWeekday = weekDay?.indexOf(dow) > -1;

    start = inWeekday
      ? timezoneDate.local()
      : moment
          .utc(
            `${currentDate
              .subtract(1, "day")
              .format(format.DASHUN)}T${startHour}`
          )
          .utcOffset((weekdayTimezoneOffset ?? 0) / 60)
          .local();
  }
  const end = moment(start).add(duration, "minutes");

  return { start, end };
}

export type CalendarAppointment = {
  appoID?: string;
  title: string;
  start: string;
  end: string;
  data?: Appointment;
  type: string;
  status?: string;
};

export type CalendarEvent = {
  title: string;
  start: string;
  end: string;
  data: {
    slots: number;
    occupied: number;
    disabled: number;
  };
  appointments: EventData[];
};

const getDaysByMonth = (month: string) => {
  const daysInMonth = moment(month).daysInMonth();
  return Array.from({ length: daysInMonth }, (_v, k) => k + 1);
};

export function formatCalendarEvents(
  monthDate: string,
  calendarEvents: { [day: string]: EventsPerDay | undefined }
) {
  const days = getDaysByMonth(monthDate);
  let events: CalendarEvent[] = [];
  days.forEach((d) => {
    const day = moment(
      moment(monthDate).format("YYYYMM") + d,
      "YYYYMMD"
    ).format(format.DASHUN);
    const ce = calendarEvents[day];
    if (ce) {
      const event: CalendarEvent = {
        title: `Disponível: ${ce.availables}\nMarcado: ${ce.markeds}\nDesabilitado: ${ce.disabled}`,
        start: moment(day).format(format.DATEHOUR),
        end: moment(day).format(format.DATEHOUR),
        data: {
          slots: ce.availables,
          disabled: ce.disabled,
          occupied: ce.markeds,
        },
        appointments: ce.appointments,
      };
      events = [...events, event];
    }
  });

  return events;
}

export function generateCalendarEvents(
  monthDate: string,
  appos: Appointment[],
  schedules: (Schedule & { doctID?: string })[],
  blocks: ScheduleLockView[],
  startDaySchedules?: string
) {
  let schedDateMap: {
    [date: string]: Omit<CalendarEvent, "appointments"> & {
      appointments: CalendarAppointment[];
    };
  } = {};

  const appoDayMap: { [day: string]: Appointment[] } = {};
  appos?.forEach?.((a) => {
    const cancelAppo =
      [
        AppointmentStatus.cancel,
        AppointmentStatus.canceledForReschedule,
        AppointmentStatus.rescheduled,
      ].indexOf(a.status as AppointmentStatus) > -1;

    if (!a || cancelAppo) {
      return;
    }
    const day = moment(a.markedAt).format(format.DASHUN);
    appoDayMap[day] = [...(appoDayMap[day] ?? []), a];
  });

  const days = getDaysByMonth(monthDate);
  let slotsByDay: {
    [day: string]: { slots: number; blocked: number } | undefined;
  } = {};

  days.forEach((d) => {
    const day = moment(
      moment(monthDate).format("YYYYMM") + d,
      "YYYYMMD"
    ).format(format.DASHUN);

    schedules.forEach((sch) => {
      const isWeekday = sch.weekDay?.length > 0;
      if (
        (!isWeekday && !moment(sch.startAt).isSame(day, "day")) ||
        !moment(day).isBetween(sch.startAt, sch.endAt, "day", "[]")
      ) {
        return;
      }
      let start = moment(sch.startAt).local();
      if (isWeekday) {
        const timezoneDate = moment
          .utc(`${day}T${moment.utc(sch.startAt).format(format.FULLTIME24H)}`)
          .utcOffset((sch?.weekdayTimezoneOffset ?? 0) / 60);
        if (timezoneDate.isBefore(day, "day")) {
          timezoneDate.add(1, "day");
        }
        const dow = getWeekDay(
          timezoneDate.format(format.DASHUN),
          format.DASHUN
        );
        if (sch.weekDay?.indexOf(dow) === -1) {
          return;
        }
        start = timezoneDate.local();
      }
      const availableBlocks = blocks.filter((b) => blockFilter(b, sch));
      const qnt = Math.floor(sch.eventDuration / sch.appointmentDuration);
      let currentDaySlots = 0;
      let nextDaySlots = 0;
      let currentDayBlocs = 0;
      let nextDayBlocs = 0;
      for (let index = 0; index < qnt; index++) {
        const startOf = start
          .clone()
          .add(index * sch.appointmentDuration, "minutes");
        const endOf = startOf.clone().add(sch.appointmentDuration, "minutes");
        if (startDaySchedules && !startOf.isAfter(moment(startDaySchedules))) {
          continue;
        }
        let isBlock = "";
        availableBlocks.forEach((block) => {
          const blockStart = moment(block?.startAt);
          const blockEnd = moment(block?.endAt);
          const inRenge =
            startOf.isBetween(blockStart, blockEnd, undefined, "[)") ||
            endOf.isBetween(blockStart, blockEnd, undefined, "()");
          if (inRenge) {
            isBlock = block.origin;
          }
        });

        if (startOf.isAfter(day, "day")) {
          if (isBlock === ScheduleLockOrigin.lock) {
            nextDayBlocs++;
          }
          if (!isBlock) {
            nextDaySlots++;
          }
          continue;
        }
        if (isBlock === ScheduleLockOrigin.lock) {
          currentDayBlocs++;
        }
        if (!isBlock) {
          currentDaySlots++;
        }
      }

      const cs = slotsByDay[day];
      slotsByDay[day] = {
        slots: (cs?.slots ?? 0) + currentDaySlots,
        blocked: (cs?.blocked ?? 0) + currentDayBlocs,
      };
      if (nextDaySlots > 0 || nextDayBlocs > 0) {
        const nextDay = moment(day).add(1, "day").format(format.DASHUN);
        const ns = slotsByDay[nextDay];
        slotsByDay[nextDay] = {
          slots: (ns?.slots ?? 0) + nextDaySlots,
          blocked: (ns?.blocked ?? 0) + nextDayBlocs,
        };
      }
    });

    const c = schedDateMap[day];
    const appoIDs = c?.appointments?.map?.((a) => a.appoID) ?? [];
    const appo = appoDayMap[day] ?? [];
    const sbd = slotsByDay[day];

    const appointmentEvents = appo
      .map((a) => {
        const sch = schedules.find((s) => s.sescID === a.sescID);
        const appointmentDuration = sch?.appointmentDuration ?? 0;
        const ca: CalendarAppointment = {
          appoID: a.appoID,
          title: a.patiName || "Paciente",
          start: moment(a.markedAt).format(format.DATEHOUR),
          end: moment(a.markedAt)
            .add(appointmentDuration, "minutes")
            .format(format.DATEHOUR),
          data: a,
          type: a.type,
          status: a.status,
        };
        return ca;
      })
      .filter((a) => appoIDs.indexOf(a.appoID) === -1);

    const scheduleSlots = sbd?.slots ?? 0;

    if (scheduleSlots > 0 || appo.length > 0 || (sbd?.blocked ?? 0) > 0) {
      const data = {
        slots: (c?.data?.slots ?? 0) + (scheduleSlots < 0 ? 0 : scheduleSlots),
        occupied: (c?.data?.occupied ?? 0) + appo.length,
        disabled: (c?.data?.disabled ?? 0) + (sbd?.blocked ?? 0),
      };
      schedDateMap[day] = {
        title: `Disponível: ${data.slots}\nMarcado: ${data.occupied}\nDesabilitado: ${data.disabled}`,
        start: moment(day).format(format.DATEHOUR),
        end: moment(day).format(format.DATEHOUR),
        data,
        appointments: [...(c?.appointments ?? []), ...appointmentEvents],
      };
    }
  });

  return schedDateMap;
}
