import React from "react";
import { connect } from "react-redux";
import { savePrivateFile } from "ducks/user";
import { PrivateFileUploadedResponse } from "@udok/lib/api/user";
import FilePickerButton from "@udok/lib/components/Input/FilePickerButton";
import Icons from "components/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface ImageLoaderProps {
  savePrivateFile: (
    file: File,
    name: string,
    permittedUser: string
  ) => Promise<PrivateFileUploadedResponse>;
  userID: string;
  icon?: any;
  inputProps?: React.InputHTMLAttributes<any>;
  onFile?: (f: PrivateFileUploadedResponse) => void;
}

const ImageLoader = (props: ImageLoaderProps) => {
  const { savePrivateFile, userID, icon, inputProps, onFile } = props;
  const [loading, setL] = React.useState(false);

  const handlePick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const f = e.target.files?.[0];
    if (!f) {
      return;
    }

    setL(true);
    savePrivateFile(f, f.name, userID)
      .then((r) => {
        onFile?.(r);
      })
      .finally(() => {
        setL(false);
      });
  };

  const waitIcon = icon ?? <Icons.InsertDriveFile />;

  return (
    <FilePickerButton
      icon={!loading ? waitIcon : <CircularProgress size={24} />}
      onChange={handlePick}
      inputProps={inputProps}
    />
  );
};

export default connect(
  () => ({}),
  (dispatch: any) => ({
    savePrivateFile: (file: File, name: string, permittedUser: string) =>
      dispatch(savePrivateFile(file, name, permittedUser)),
  })
)(ImageLoader);
