import React from "react";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { configureStore } from "store";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "screens";
import Onboarding from "screens/onboarding";

// prepare store
const history = createBrowserHistory();
const store = configureStore(history);

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Switch>
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/:slug" component={Main} />
        </Switch>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
