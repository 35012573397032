import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  getOneQuickResponses,
  fetchCachedQuickResponses,
  searchQuickResponse,
  quickResponseSearchView,
} from "ducks/messaging";
import { QuickResponseFilter } from "@udok/lib/api/models";

export const useSearchQuickResponse = () => {
  const [loaders, setLoaders] = React.useState(
    new Map<Promise<any>, boolean>()
  );
  const dispatch: AppDispatch = useDispatch();

  const search = React.useCallback(
    (filter?: QuickResponseFilter) => {
      const load = dispatch(searchQuickResponse(filter)).finally(() => {
        setLoaders((l) => {
          l.delete(load);
          return new Map(l);
        });
      });
      setLoaders((l) => new Map(l.set(load, true)));
    },
    [dispatch]
  );

  const { options } = useSelector(quickResponseSearchView);
  const loading = Array.from(loaders.values()).reduce((a, b) => a || b, false);

  return [loading, search, options] as [
    typeof loading,
    typeof search,
    typeof options
  ];
};

export const useGetQuickResponse = (quickID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchCachedQuickResponses(quickID)).finally(() =>
      setLoading(false)
    );
  }, [dispatch, quickID]);

  const getQuickResponses = React.useCallback(
    getOneQuickResponses({ quickID }),
    [quickID]
  );
  const { quickResponse } = useSelector(getQuickResponses);

  return [loading, quickResponse] as [typeof loading, typeof quickResponse];
};
