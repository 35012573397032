import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  CircularProgress,
  CardActions,
  CardMedia,
  CardMediaProps,
  Typography,
  CardContent,
  Card,
  Button,
  ButtonProps,
  DialogContent,
  Dialog,
  Popover,
  MenuItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import Icons from "@udok/lib/components/Icon";
import DialogTitle from "@udok/lib/components/Dialog/Title";
import ZoomImage from "@udok/lib/components/ZoomImage";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import "react-h5-audio-player/lib/styles.css";
import clsx from "clsx";
import TabPanel from "@udok/lib/components/TabPanel";
import { format } from "@udok/lib/internal/util";
import moment from "moment";

moment.locale("pt-br");

const useStyles = (p: {
  isSenderMessage: boolean;
  firstOfUserOrGroup: boolean;
  last: boolean;
  color: string;
  smallWindow: boolean;
}) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: p.isSenderMessage ? "flex-end" : "flex-start",
        margin: "auto",
        maxWidth: p.smallWindow ? 356 : 960,
      },
      cardActions: {
        justifyContent: "space-between",
        fontSize: 12,
        padding: "2px 8px",
      },
      innerWrap: {
        width: p.smallWindow ? 316 : 476,
        marginLeft: p.smallWindow ? "2%" : "10%",
        marginRight: p.smallWindow ? "2%" : "10%",
        paddingTop: !p.firstOfUserOrGroup ? 2 : 18,
        paddingBottom: p.last ? 18 : 2,
        position: "relative",
        [theme.breakpoints.only("xs")]: {
          width: 276,
          marginLeft: "1%",
          marginRight: "1%",
        },
      },
      card: {
        width: p.smallWindow ? 300 : 460,
        marginLeft: 8,
        boxShadow: "none",
        borderTopLeftRadius:
          !p.isSenderMessage && p.firstOfUserOrGroup ? 0 : undefined,
        borderTopRightRadius:
          p.isSenderMessage && p.firstOfUserOrGroup ? 0 : undefined,
        backgroundColor: p.color,
        position: "relative",
        [theme.breakpoints.only("xs")]: {
          width: 260,
        },
      },
      cardHover: {
        "&:hover": {
          "& $messageOptions": {
            display: "flex",
          },
        },
      },
      messageOptions: {
        width: 254,
        height: "auto",
        position: "absolute",
        right: 0,
        top: 0,
        background:
          "linear-gradient(15deg, rgba(254,254,254,0) 70%, rgba(200,200,200,0.9556023092830882) 100%)",
        display: "none",
        justifyContent: "flex-end",
        alignItems: "flex-start",
      },
      cardContent: {
        padding: theme.spacing(1),
        paddingBottom: 0,
      },
      deletedContainer: {
        display: "flex",
        alignItems: "center",
      },
      deletedMessage: {
        marginLeft: theme.spacing(1),
      },
    })
  );

export type Actions = {
  label: string;
  onlyInTypes?: ("image" | "audio" | "text" | "videosession" | "archive")[];
  enableToReceived?: boolean;
  onClick: (message: Message) => void;
};

const MoreActions = ({
  open,
  close,
  anchorEl = null,
  actions = [],
  message,
}: {
  open: boolean;
  close: () => void;
  anchorEl: HTMLElement | null;
  actions: Actions[];
  message: Message;
}) => {
  const messageType = message?.deletedAt ? "deleted" : message?.type;
  const validActions = actions.filter((a) =>
    (a?.onlyInTypes ?? []).length > 0
      ? (a?.onlyInTypes ?? []).indexOf(messageType) !== -1
      : true
  );

  return (
    <Popover
      key={`${message?._id ?? ""}_popover`}
      open={open && Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={close}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{ zIndex: 999999 }}
    >
      {validActions.map((a) => (
        <MenuItem
          onClick={() => {
            a.onClick(message);
            close();
          }}
        >
          <ListItemText primary={a.label} />
        </MenuItem>
      ))}
    </Popover>
  );
};

export type ComponentProps = {
  fileID: string;
  text: string;
  userID: string;
  viseID: string;
  megrID?: string;
};
export type Message = {
  [k: string]: any;
  status: string[];
};
export interface ChatMessageProps {
  index: number;
  message: Message;
  previousMessage: any;
  last: boolean;
  size: "small" | "large";
  moreActions?: Actions[];
  messageImageComponent?: (p: ComponentProps) => React.ReactNode;
  messageAudioComponent?: (p: ComponentProps) => React.ReactNode;
  messageVideoComponent?: (p: ComponentProps) => React.ReactNode;
  messageArchiveComponent?: (p: ComponentProps) => React.ReactNode;
  cardActions?: (p: ComponentProps) => React.ReactNode;
  handleDownloadImage: (message: Message) => void;
}

const [rendererDialog, promiseDialog] = createModal(MoreActions);
const ChatMessage = ({
  message,
  previousMessage,
  last,
  size,
  moreActions = [],
  messageImageComponent,
  messageAudioComponent,
  messageVideoComponent,
  messageArchiveComponent,
  cardActions,
}: ChatMessageProps) => {
  const curUserID = message?.user?.currentUser?.userID;
  const messageUserID = message?.user?._id;
  const isSenderMessage = curUserID === messageUserID;
  const firstOfUserOrGroup =
    messageUserID !== previousMessage?.user?._id ||
    message?.createdAt?.slice(0, 10) !==
      previousMessage?.createdAt?.slice(0, 10);
  const color = isSenderMessage ? "#C9EFF2" : "#fff";
  const classes = useStyles({
    isSenderMessage,
    firstOfUserOrGroup,
    last,
    color,
    smallWindow: size === "small",
  })();
  const componentPorps: ComponentProps = {
    fileID: message?.fileID,
    text: message?.text,
    userID: message?.user?._id,
    viseID: message?.data?.viseID,
    megrID: message?.megrID,
  };

  const actions = moreActions.filter((action) => {
    if (action.enableToReceived) {
      return true;
    }
    return isSenderMessage;
  });

  const handlepressMore = React.useCallback(
    async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (actions.length > 0) {
        const anchorEl = e.currentTarget;
        try {
          await promiseDialog({
            anchorEl,
            message,
            actions,
          });
        } catch (e) {
          console.warn(e);
        }
      }
    },
    [message, actions]
  );

  const messageType = message?.deletedAt ? "deleted" : message?.type;
  return (
    <div className={classes.root}>
      <div className={classes.innerWrap}>
        {!firstOfUserOrGroup ? null : !isSenderMessage ? (
          <div style={{ left: 0, position: "absolute", top: 17 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 13"
              width="8"
              height="13"
              color={color}
            >
              <path
                opacity=".13"
                fill="#0000000"
                d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
              ></path>
              <path
                fill="currentColor"
                d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
              ></path>
            </svg>
          </div>
        ) : (
          <div style={{ right: 0, position: "absolute", top: 17 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 13"
              width="8"
              height="13"
              color={color}
            >
              <path
                opacity=".13"
                d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
              ></path>
              <path
                fill="currentColor"
                d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
              ></path>
            </svg>
          </div>
        )}
        <Card
          className={clsx(classes.card, {
            [classes.cardHover]: actions.length > 0 && !message?.deletedAt,
          })}
        >
          <TabPanel value={messageType} index="image">
            <>{messageImageComponent?.(componentPorps)}</>
          </TabPanel>
          <TabPanel value={messageType} index="audio">
            <>{messageAudioComponent?.(componentPorps)}</>
          </TabPanel>
          <TabPanel value={messageType} index="text">
            <CardContent className={classes.cardContent}>
              <Typography
                style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
              >
                {message?.text}
              </Typography>
            </CardContent>
          </TabPanel>
          <TabPanel value={messageType} index="videosession">
            <>{messageVideoComponent?.(componentPorps)}</>
          </TabPanel>
          <TabPanel value={messageType} index="archive">
            <>{messageArchiveComponent?.(componentPorps)}</>
          </TabPanel>
          <TabPanel value={messageType} index="deleted">
            <>
              <CardContent
                className={clsx(classes.cardContent, classes.deletedContainer)}
              >
                <Icons.NotInterested color="disabled" />
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  className={classes.deletedMessage}
                >
                  <i>Mensagem apagada</i>
                </Typography>
              </CardContent>
            </>
          </TabPanel>

          <CardActions className={classes.cardActions} disableSpacing>
            <div>{cardActions?.(componentPorps)}</div>
            <div>
              {moment.utc(message?.createdAt).local().format(format.TIME24H)}
              {message?.loading ? (
                <>
                  &nbsp;
                  <CircularProgress size={10} style={{ color: "grey" }} />
                </>
              ) : null}
            </div>
          </CardActions>
          <div className={classes.messageOptions} onClick={handlepressMore}>
            <Icons.ExpandMore style={{ cursor: "pointer", color: "#fff" }} />
          </div>
          {rendererDialog}
        </Card>
      </div>
    </div>
  );
};

export const LoadingButton = (props: { loading?: boolean } & ButtonProps) => (
  <Button
    {...props}
    endIcon={props.loading ? <CircularProgress size={48} /> : props.endIcon}
  />
);

const useImageStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      padding: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        minWidth: 600,
        minHeight: 600,
      },
    },
    dialogTitle: {
      margin: 0,
      backgroundColor: theme.palette.primary.lightest,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    closeButton: {
      color: theme.palette.primary.light,
    },
    toolsItens: {
      marginRight: theme.spacing(1),
      border: "solid 1px",
      borderRadius: "5px",
      height: 40,
    },
  })
);

export const EnlargeImage = (
  props: {
    image: string;
    title: string;
    handleDownloadImage: (message: Message) => void;
    message: Message;
  } & Omit<CardMediaProps, "image" | "title">
) => {
  const [open, setOpen] = React.useState(false);
  const classes = useImageStyles();
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a
        href={props.image}
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <CardMedia {...props} />
      </a>
      <Dialog fullScreen open={open} onClose={onClose}>
        <DialogTitle
          id="available-schedules"
          className={classes.dialogTitle}
          onClose={onClose}
          titleTypographyProps={{
            color: "primary",
            variant: "h4",
          }}
          closeButtonProps={{
            className: classes.closeButton,
          }}
        >
          {props.title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <ZoomImage
            src={props.image}
            alt={props.title}
            toolbar={
              <IconButton
                color="primary"
                className={classes.toolsItens}
                onClick={() => props.handleDownloadImage(props.message)}
              >
                <Icons.CloudDownload />
              </IconButton>
            }
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChatMessage;
