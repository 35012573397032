import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  CreditCard,
  CreditCardForm,
  InvoiceView,
  Payment,
  Voucher,
  SendMailVoucherData,
} from "@udok/lib/api/models";
import {
  fetchCreditCards,
  fetchCreditCard,
  deleteCreditCard,
  createCreditCard,
  createPayment,
  PaymentForm,
  refundPayment,
  createVoucher,
  sendVoucherEmail,
} from "@udok/lib/api/billing";
import { getToken, UNAUTHORIZED } from "./auth";
// @ts-ignore
import pagarme from "pagarme";
import moment from "moment";
import { format } from "@udok/lib/internal/util";

export type InitialState = {
  creditCardByID: { [crcaID: string]: CreditCard | undefined };
  invoicesByID: { [invoID: string]: InvoiceView | undefined };
  listedInvoices: string[];
  voucher?: Voucher;
};

// Reducers
const initialState: InitialState = {
  creditCardByID: {},
  invoicesByID: {},
  listedInvoices: [],
};

class CreditCardSlice extends Hen<InitialState> {
  creditCardLoaded(v: CreditCard) {
    this.state.creditCardByID[String(v.crcaID)] = v;
  }
  creditCardsLoaded(v: CreditCard[]) {
    v.forEach((s) => {
      this.state.creditCardByID[String(s.crcaID)] = s;
    });
  }
  creditCardRemoved(v: CreditCard) {
    delete this.state.creditCardByID[String(v.crcaID)];
  }

  invoicesLoaded(v: InvoiceView[]) {
    const list = v.map((s) => {
      this.state.invoicesByID[s.invoID] = s;
      return s.invoID;
    });
    this.state.listedInvoices = list;
  }
  invoiceLoaded(v: InvoiceView) {
    this.state.invoicesByID[v.invoID] = v;
  }
  invoicePaid(invoID: string, paymID: string, status: string) {
    const curr = this.state.invoicesByID[invoID];
    if (!curr) {
      return;
    }
    this.state.invoicesByID[invoID] = {
      ...curr,
      paymID,
      paymentStatus: status,
    };
  }
  invoiceRefunded(invoID: string, paymID: string, status: string) {
    const curr = this.state.invoicesByID[invoID];
    if (!curr) {
      return;
    }
    this.state.invoicesByID[invoID] = {
      ...curr,
      paymID,
      paymentStatus: status,
    };
  }
  voucherLoaded(v: Voucher) {
    this.state.voucher = v;
  }
}

export const [Reducer, actions] = hen(new CreditCardSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.billing;
export const invoicesByIDSelector = (state: RootState) =>
  state.billing.invoicesByID;
export const listedInvoicesSelector = (state: RootState) =>
  state.billing.listedInvoices;
const voucherSelector = (state: RootState) => state.billing.voucher;

export const voucherView = createSelector([voucherSelector], (voucher) => {
  return {
    voucher,
  };
});

export const creditCardsView = createSelector(mainSelector, (state) => {
  return {
    list: (Object.keys(state.creditCardByID)
      .map((crcaID) => state.creditCardByID[crcaID])
      .filter((cc) => !!cc) ?? []) as CreditCard[],
  };
});

export const invoiceView = (state: RootState, props: { invoID: string }) =>
  createSelector([mainSelector], (state) => {
    return {
      invoice: state.invoicesByID[props.invoID],
    };
  });

export const oneCreditCardView = (props: { crcaID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      creditCard: state.creditCardByID[props.crcaID],
    };
  });

// Actions
export function loadCreditCards(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCreditCards(apiToken)
      .then(async (r) => {
        dispatch(actions.creditCardsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneCreditCard(crcaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCreditCard(apiToken, crcaID)
      .then(async (r) => {
        dispatch(actions.creditCardLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedCreditCard(crcaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const CardExist = Boolean(state.billing.creditCardByID[crcaID]);
    if (CardExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneCreditCard(crcaID));
  };
}

export function createOneCreditCard(
  creditCard: CreditCardForm
): AppThunk<Promise<CreditCard>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const card = {
      card_number: creditCard.number,
      card_holder_name: creditCard.name,
      card_expiration_date: creditCard.expiry,
      card_cvv: creditCard.cvv,
    };

    let hash = "";
    const errMsg = "Erro ao registrar cartão no gateway";
    try {
      hash = await pagarme.client
        .connect({ encryption_key: process.env.REACT_APP_PAGARME_CRYPTO_KEY })
        .then((client: any) => {
          return client.security.encrypt(card);
        });
    } catch (e) {
      dispatch(
        newNotification("general", {
          status: "error",
          message: errMsg,
        })
      );
      throw new Error(errMsg);
    }
    if (!hash) {
      newNotification("general", {
        status: "error",
        message: errMsg,
      });
      throw new Error(errMsg);
    }

    const cc = {
      name: creditCard.number.replace(/\s/g, "").slice(-4),
      brand: creditCard.brand,
      provider: "pagarme",
      providerCard: hash,
      expiresAt: moment(creditCard.expiry, format.MONYEAR).format(
        format.RFC3349
      ),
      billingAddress: creditCard.billingAddress,
    };
    return createCreditCard(apiToken, cc)
      .then((r) => {
        dispatch(actions.creditCardLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeOneCreditCard(crcaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteCreditCard(apiToken, crcaID)
      .then((r) => {
        dispatch(actions.creditCardRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function payInvoice(d: PaymentForm): AppThunk<Promise<Payment>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createPayment(apiToken, d)
      .then((r) => {
        dispatch(actions.invoicePaid(d.invoID, r.paymID, r.status));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function refundInvoicePayment(
  paymID: string
): AppThunk<Promise<Payment>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return refundPayment(apiToken, paymID)
      .then((r) => {
        dispatch(actions.invoiceRefunded(r.invoID, r.paymID, r.status));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createOneVoucher(expiredAt: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createVoucher(apiToken, expiredAt)
      .then((r) => {
        dispatch(actions.voucherLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function sendVoucherLinkEmail(
  mail: SendMailVoucherData
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return sendVoucherEmail(apiToken, mail)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Email enviado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
