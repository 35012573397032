import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import {
  avatarByUserIDViewCreator,
  fetchCachedProfileForUserID,
} from "ducks/user";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  })
);

export interface UserAvatarProps {
  userID: string;
  style?: React.CSSProperties;
}
function UserAvatar(props: UserAvatarProps) {
  const { style, userID } = props;
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const getAvatar = React.useCallback(avatarByUserIDViewCreator({ userID }), [
    userID,
  ]);
  const { src, name } = useSelector(getAvatar);

  React.useEffect(() => {
    if (!Boolean(src) && Boolean(userID)) {
      dispatch(fetchCachedProfileForUserID(userID));
    }
  }, [dispatch, src, userID]);

  return (
    <Avatar style={style} className={classes.root} alt={name} src={src}>
      {src ? undefined : name?.[0]?.toUpperCase?.() ?? "U"}
    </Avatar>
  );
}

export default React.memo(UserAvatar);
