import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { formatCalendarEvents } from "@udok/lib/app/schedule";
import {
  Schedule,
  Appointment,
  AppointmentStatus,
  NewAppointment,
  ResourceType,
  DocumentData,
  AppointmentCollectDocumentPresentation,
  InvoiceView,
  ScheduleLock,
  FilterScheduleLock,
  FilterAppointment,
  AppointmentConfirmation,
  ScheduleLockView,
  ScheduleLockOrigin,
  AppointmentStatusData,
  AppointmentStatusResponse,
  ScheduleFilter,
  CreateScheduleLockForm,
  AppointmentRescheduleCreate,
  AppointmentReschedule,
  AppointmentReturnPending,
  ExamProcedure,
  AppointmentCommunication,
  DisplayAppointmentReview,
  FilterAppointmentReview,
  FilterAttachment,
  ScheduleSlot,
  FilterScheduleSlots,
  EventsPerDay,
  FilterCalendarEvents,
  AppointmentCheckIn,
  FilterAppointmentCheckIn,
  NewAppointmentStatus,
} from "@udok/lib/api/models";
import {
  fetchSchedules,
  fetchSchedule,
  updateSchedule,
  deleteSchedule,
  createSchedule,
  createAppointment,
  sendControlledAppointmentEmail,
  sendControlledAppointmentPhone,
  sendEditStatusAppointment,
  fetchAppointment,
  fetchAttachmentsByappoID,
  fetchAppointmentCollectDocument,
  createScheduleLock,
  fetchListScheduleLock,
  deleteScheduleLock,
  confirmAppointment,
  fetchAppointmentStatus,
  createAppointmentReschedule,
  fetchAppointsReturnPending,
  fetchAppointmentCommunication,
  sendNotificationsPhoneAppointmentConfirmation,
  sendAppointmentReview,
  fetchListAppointmentReview,
  appointmentDoctorTransfer,
  fetchScheduleSlots,
  fetchCanceableCalendarEvents,
  fetchCanceableAppointments,
  createAppointmentCheckIn,
  fetchAppointmentCheckIns,
  fetchAppointmentCheckIn,
  deleteAppointmentCheckIn,
} from "@udok/lib/api/schedule";
import { fetchLocation } from "@udok/lib/api/location";
import {
  invoicesByIDSelector,
  refundInvoicePayment,
  actions as actionsBilling,
} from "./billing";
import { fetchDocument } from "@udok/lib/api/document";
import { doctorRepositoryByUserID, clinicDoctorRepository } from "ducks/doctor";
import {
  actions as locationActions,
  locationByIDSelector,
} from "ducks/location";
import { format } from "@udok/lib/internal/util";
import { getToken, UNAUTHORIZED } from "./auth";
import { fetchInvoicesByAppoID } from "@udok/lib/api/billing";
import { OnboardingPatientForm, OnboardingResponse } from "@udok/lib/api/auth";
import { DayViewAppo } from "@udok/lib/app/appointment";
import {
  createControlledPatient,
  actions as patientActions,
  patientRepository,
} from "./patient";
import { clinicSpecialtySelector, plansSelector } from "ducks/clinic";
import { proceduresSelector } from "ducks/procedure";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type CalendarFilter = {
  doctID?: string;
  specID?: string;
  patiID?: string;
  exprID?: string;
};

export type InitialState = {
  calendarFilter: CalendarFilter;
  appointmentsByDay: { [day: string]: string[] | undefined };
  slotsByDay: { [day: string]: ScheduleSlot[] | undefined };
  calendarEvents: { [day: string]: EventsPerDay | undefined };
  scheduleByID: { [sescID: string]: Schedule | undefined };
  appointmentByID: { [appoID: string]: Appointment | undefined };
  appoIDtByMegrID: { [megrID: string]: string | undefined };
  appointmentStatusHistoryByID: {
    [appoID: string]: AppointmentStatusData[] | undefined;
  };
  documentByID: { [docuID: string]: DocumentData };
  docuIDByAppoID: { [appoID: string]: string[] };
  collectDocAttachByID: {
    [apcdID: string]: AppointmentCollectDocumentPresentation;
  };
  collectDocAttachByAppoID: { [appoID: string]: string[] };
  invoicesByAppoID: { [invoID: string]: string[] };
  scheduleLockByID: { [scloID: string]: ScheduleLockView | undefined };
  listedScheduleLock: string[];
  appointmentReturnPending: {
    [doctID: string]: {
      [patiID: string]: AppointmentReturnPending | undefined;
    };
  };
  appointmentCommunicationByID: {
    [appoID: string]: AppointmentCommunication | undefined;
  };
  reviews: DisplayAppointmentReview[];
  appointmentCheckInByID: { [appoID: string]: AppointmentCheckIn | undefined };
};

// Reducers
const initialState: InitialState = {
  scheduleByID: {},
  appointmentByID: {},
  appointmentsByDay: {},
  appointmentStatusHistoryByID: {},
  calendarFilter: {},
  documentByID: {},
  docuIDByAppoID: {},
  collectDocAttachByID: {},
  collectDocAttachByAppoID: {},
  invoicesByAppoID: {},
  scheduleLockByID: {},
  listedScheduleLock: [],
  appoIDtByMegrID: {},
  appointmentReturnPending: {},
  appointmentCommunicationByID: {},
  reviews: [],
  slotsByDay: {},
  calendarEvents: {},
  appointmentCheckInByID: {},
};

class ScheduleSlice extends Hen<InitialState> {
  scheduleLoaded(v: Schedule) {
    this.state.scheduleByID[String(v.sescID)] = v;
  }

  schedulesLoaded(v: Schedule[]) {
    v.forEach((s) => {
      this.state.scheduleByID[String(s.sescID)] = s;
    });
  }

  scheduleRemoved(v: Schedule) {
    delete this.state.scheduleByID[String(v.sescID)];
  }

  removeSchedulesByUserID(userID: string) {
    Object.keys(this.state.scheduleByID).forEach((sescID) => {
      const s = this.state.scheduleByID[sescID];
      if (s?.userID === userID) {
        delete this.state.scheduleByID[sescID];
      }
    });
  }

  appointmentLoaded(v: Appointment) {
    this.state.appointmentByID[String(v.appoID)] = v;
    if (v?.megrID) {
      this.state.appoIDtByMegrID[v.megrID] = v.appoID;
    }
    const markedDate = moment.utc(v.markedAt).local().format(format.DASHUN);
    const current = this.state.appointmentsByDay?.[markedDate] ?? [];
    this.state.appointmentsByDay[markedDate] = Array.from(
      new Set([...current, v.appoID])
    );
  }

  appointmentsLoaded(v: Appointment[]) {
    v.forEach((s) => {
      this.state.appointmentByID[String(s.appoID)] = s;
      if (s?.megrID) {
        this.state.appoIDtByMegrID[s.megrID] = s.appoID;
      }
      const markedDate = moment.utc(s.markedAt).local().format(format.DASHUN);
      const current = this.state.appointmentsByDay?.[markedDate] ?? [];
      this.state.appointmentsByDay[markedDate] = Array.from(
        new Set([...current, s.appoID])
      );
    });
  }

  calendarFilterMerged(f: CalendarFilter) {
    this.state.calendarFilter = { ...this.state.calendarFilter, ...f };
  }

  listdocumentsLoaded(v: DocumentData[]) {
    v.forEach((s) => {
      this.state.documentByID[String(s.docuID)] = s;
    });
  }
  documentsLoaded(v: DocumentData[], appoID: string) {
    const appolist = this.state.docuIDByAppoID[appoID] ?? [];
    v.forEach((s) => {
      if (s) {
        const index = appolist.findIndex((ID) => ID === s.docuID);
        if (index === -1) {
          appolist.push(s.docuID);
        }
        this.state.documentByID[String(s.docuID)] = s;
      }
    });
    this.state.docuIDByAppoID[appoID] = appolist;
  }

  collectAppoDocumentsLoaded(
    v: AppointmentCollectDocumentPresentation[],
    appoID: string
  ) {
    const appolist = this.state.collectDocAttachByAppoID[appoID] ?? [];
    v.forEach((s) => {
      if (s) {
        const index = appolist.findIndex((ID) => ID === s.apcdID);
        if (index === -1) {
          appolist.push(s.apcdID);
        }
        this.state.collectDocAttachByID[String(s.apcdID)] = s;
      }
    });
    this.state.collectDocAttachByAppoID[appoID] = appolist;
  }

  invoicesLoaded(v: InvoiceView[], appoID: string) {
    this.state.invoicesByAppoID[appoID] = v.map((s) => {
      return s.invoID;
    });
  }
  clearInvoicesLoaded() {
    this.state.invoicesByAppoID = {};
  }

  scheduleLockLoad(lock: ScheduleLockView) {
    this.state.scheduleLockByID[lock.id] = lock;
  }

  scheduleLockListLoad(locks: ScheduleLockView[]) {
    const list = locks.map((lock) => {
      this.state.scheduleLockByID[lock.id] = lock;
      return lock.id;
    });
    this.state.listedScheduleLock = list;
  }

  scheduleLockRemoved(lock: ScheduleLock) {
    delete this.state.scheduleLockByID[lock.scloID];
  }

  appointmentConfirmationLoaded(appoID: string, v: AppointmentConfirmation) {
    let a = this.state.appointmentByID[appoID]!;
    a.confirmations = [...(a?.confirmations ?? []), v];
    this.state.appointmentByID[appoID] = a;
  }

  appointmentStatusLoaded(appoID: string, d: AppointmentStatusData[]) {
    const listStatus = d.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
    const latestStatus = listStatus[0];
    if (this.state.appointmentByID[appoID]) {
      this.state.appointmentByID[appoID] = {
        ...(this.state.appointmentByID[appoID] as any),
        status: latestStatus.status,
        statusInfo: latestStatus.info,
      };
    }
    this.state.appointmentStatusHistoryByID[appoID] = listStatus;
  }

  appointmentStatusChange(s: AppointmentStatusResponse) {
    const statusData: AppointmentStatusData = {
      info: s.info,
      status: s.status,
      appoID: s.appoID,
      apstID: s.apstID,
      createdAt: s.createdAt,
      createdBy: s.createdBy,
      nameCreatedBy: s.nameCreatedBy ?? "",
      reasonDescription: s.reasonDescription,
      reason: "clinic",
    };
    const history = this.state.appointmentStatusHistoryByID[s.appoID] ?? [];
    this.state.appointmentByID[s.appoID] = {
      ...this.state.appointmentByID[s.appoID]!,
      status: s.status,
      statusInfo: s.info,
    };
    this.state.appointmentStatusHistoryByID[s.appoID] = [
      statusData,
      ...history,
    ];
  }

  appoRescheduleLoaded(r: AppointmentReschedule) {
    let appo = this.state.appointmentByID[r.appoID];
    if (appo) {
      appo = {
        ...appo,
        markedAt: r.markedAt,
        status: AppointmentStatus.confirmedAndReschedule,
      };
      this.state.appointmentByID[r.appoID] = appo;
    }
  }

  pendingReturnLoaded(returns: AppointmentReturnPending[]) {
    returns.forEach((r) => {
      const docReturn = this.state.appointmentReturnPending[r.doctID];
      this.state.appointmentReturnPending[r.doctID] = {
        ...docReturn,
        [r.patiID]: r,
      };
    });
  }
  appointmentCommunicationLoaded(ac: AppointmentCommunication) {
    this.state.appointmentCommunicationByID[ac.appoID] = ac;
  }

  reviewsLoaded(r: DisplayAppointmentReview[]) {
    this.state.reviews = r;
  }

  scheduleSlotLoaded(date: string, slots: ScheduleSlot[]) {
    this.state.slotsByDay[date] = slots;
  }

  calendarEventsLoaded(events: EventsPerDay[]) {
    events.forEach((ev) => {
      this.state.calendarEvents[ev.date] = ev;
    });
  }

  appointmentCalendarLoaded(app: Appointment) {
    const day = moment(app.markedAt).format(format.DASHUN);
    const current = this.state.calendarEvents[day];
    const endAt = moment(app.markedAt)
      .add(app.appointmentDuration, "minute")
      .format(format.DATEHOUR);
    let appointments = [...(current?.appointments ?? [])];
    const index = appointments.findIndex((a) => a.appoID === app.appoID);
    const newAppointment = {
      endAt,
      appoID: app.appoID,
      startAt: app.markedAt,
      status: app.status,
      title: app.patiName ?? "",
      type: app.type,
      confirmations: app.confirmations,
    };
    if (index === -1) {
      appointments = [...appointments, newAppointment];
    } else {
      appointments[index] = newAppointment;
    }

    this.state.calendarEvents[day] = {
      date: day,
      disabled: current?.disabled ?? 0,
      markeds: (current?.markeds ?? 0) + 1,
      availables: current?.availables ?? 0,
      appointments,
    };
  }

  appointmentCheckInRemoved(checkIn: AppointmentCheckIn) {
    delete this.state.appointmentCheckInByID[checkIn.appoID];
  }

  appointmentCheckInLoaded(checkIn: AppointmentCheckIn) {
    this.state.appointmentCheckInByID[checkIn.appoID] = checkIn;
  }

  appointmentCheckInsLoaded(checkIns: AppointmentCheckIn[]) {
    checkIns.forEach((checkIn) => {
      this.state.appointmentCheckInByID[checkIn.appoID] = checkIn;
    });
  }
}

export const [Reducer, actions] = hen(new ScheduleSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.schedule;
const calendarFilterSelector = (state: RootState) =>
  state.schedule.calendarFilter;
export const scheduleLockSelector = (state: RootState) =>
  state.schedule.scheduleLockByID;
export const listedScheduleLockSelector = (state: RootState) =>
  state.schedule.listedScheduleLock;
export const documentSelector = (state: RootState) =>
  state.schedule.documentByID;
export const statusHistorySelector = (state: RootState) =>
  state.schedule.appointmentStatusHistoryByID;
export const appointmentSelector = (state: RootState) =>
  state.schedule.appointmentByID;
export const appointmentsByDaySelector = (state: RootState) =>
  state.schedule.appointmentsByDay;
export const scheduleSelector = (state: RootState) =>
  state.schedule.scheduleByID;
export const appoIDtByMegrIDSelector = (state: RootState) =>
  state.schedule.appoIDtByMegrID;
const pendingReturnSelector = (state: RootState) =>
  state.schedule.appointmentReturnPending;
const appointmentCommunicationRepository = (state: RootState) =>
  mainSelector(state).appointmentCommunicationByID;
const appointmentReviewsRepository = (state: RootState) =>
  mainSelector(state).reviews;
const slotsByDayRepository = (state: RootState) =>
  mainSelector(state).slotsByDay;
const calendarEventsRepository = (state: RootState) =>
  mainSelector(state).calendarEvents;
const invoicesByAppoIDRepository = (state: RootState) =>
  mainSelector(state).invoicesByAppoID;
const appointmentCheckInByIDRepository = (state: RootState) =>
  mainSelector(state).appointmentCheckInByID;

export const oneAppointmentCheckIn = (props: { appoID: string }) =>
  createSelector(
    [appointmentCheckInByIDRepository],
    (appointmentCheckInByID) => {
      return {
        CheckIn: appointmentCheckInByID[props.appoID],
      };
    }
  );

export const calendarFilterData = createSelector(
  [
    calendarFilterSelector,
    clinicDoctorRepository,
    clinicSpecialtySelector,
    patientRepository,
    proceduresSelector,
  ],
  (filter, doctorRepo, specialtyRepo, patientRepo, procedures) => {
    const { doctID, specID, patiID, exprID } = filter;
    const filtredDoctor = doctID ? doctorRepo[doctID] : undefined;
    const filtredspecialty = specID ? specialtyRepo[specID] : undefined;
    const filtredPatient = patiID ? patientRepo[patiID] : undefined;
    const filtredProcedures = exprID ? procedures[exprID] : undefined;
    return {
      filtredDoctor,
      filtredspecialty,
      filtredPatient,
      filtredProcedures,
    };
  }
);

export const oneAppointmentStatusHistoryView = (
  state: RootState,
  props: { appoID: string }
) =>
  createSelector([statusHistorySelector], (state) => {
    return {
      history: state[props.appoID],
    };
  });

export const getAllAppointmentReview = createSelector(
  [appointmentReviewsRepository],
  (reviews) => {
    return { reviews };
  }
);

export const oneScheduleView = (state: RootState, props: { sescID: string }) =>
  createSelector(
    [mainSelector, locationByIDSelector],
    (state, locationByID) => {
      const schedule = state.scheduleByID[props.sescID];
      const location = schedule?.locaID
        ? locationByID[schedule.locaID]
        : undefined;

      return {
        schedule,
        location,
      };
    }
  );

export const getSchedulesList = (props: { sescIDs: string[] }) =>
  createSelector([scheduleSelector], (scheduleByID) => {
    return {
      schedules: (props?.sescIDs ?? [])
        .map((sescID) => scheduleByID[sescID])
        .filter((s) => !!s) as Schedule[],
    };
  });

export const scheduleSlotByDay = (props: {
  //selectedDay must be in the DASHUN format
  selectedDay: string;
}) =>
  createSelector([slotsByDayRepository], (slotsByDay) => {
    return {
      scheduleSlots: slotsByDay?.[props.selectedDay] ?? [],
    };
  });

export const getCalendarFilter = createSelector(
  [calendarFilterSelector],
  (calendarFilter) => {
    return { calendarFilter };
  }
);

export const appointmentCalendarView = (props: { daySelected: string }) =>
  createSelector([calendarEventsRepository], (events) => {
    const date = moment(props.daySelected, format.YEAMON);
    return {
      calendarEvents: formatCalendarEvents(date.format(format.DASHUN), events),
    };
  });

export const appointmentDayView = (props: {
  day: string;
  excludeStatus?: string[];
  ignoreFilters?: boolean;
}) =>
  createSelector(
    [
      mainSelector,
      calendarFilterSelector,
      clinicDoctorRepository,
      locationByIDSelector,
      plansSelector,
      proceduresSelector,
    ],
    (state, filter, doctors, locationByID, healthplanByID, proceduresByID) => {
      const doctorFiltered = filter.doctID ? doctors[filter.doctID] : undefined;

      const appointments = Object.keys(state.appointmentByID)
        .map((s) => state.appointmentByID[s])
        .filter((a) => !!a && !a.imcsID) as Appointment[];

      const allList = appointments.filter((a) => {
        if ((props.excludeStatus?.indexOf?.(a.status) ?? -1) > -1) {
          return false;
        }
        if (!a.markedAt) {
          return false;
        }
        if (moment(a.markedAt).format(format.SPTBR) !== props.day) {
          return false;
        }
        if (props?.ignoreFilters) {
          return true;
        }
        if (filter?.patiID && a.patiID !== filter?.patiID) {
          return false;
        }
        if (
          filter?.exprID &&
          (a?.procedures ?? []).indexOf(filter.exprID) === -1
        ) {
          return false;
        }
        const doc = doctors[a?.doctID ?? ""];
        if (
          filter?.specID &&
          !doc?.specialty?.some?.(
            (item) => String(item?.specID) === filter.specID
          )
        ) {
          return false;
        }
        if (doctorFiltered && doctorFiltered.doctID !== a?.doctID) {
          return false;
        }
        return true;
      });
      const dayEvents = allList.map((app) => {
        const location = app?.locaID ? locationByID[app.locaID] : undefined;
        const doctor = app?.doctID ? doctors[app?.doctID] : undefined;
        const procedures = (app?.procedures ?? [])
          .map((id) => proceduresByID[id])
          .filter((p) => !!p) as ExamProcedure[];
        const plan = healthplanByID?.[app?.healthplans?.[0]];
        return {
          appointment: app,
          location: location,
          doctor: doctor,
          procedures,
          plan,
        } as DayViewAppo;
      });
      return {
        dayEvents,
      };
    }
  );

export const createClinicAppointmentView = createSelector(
  [scheduleSelector, doctorRepositoryByUserID, scheduleLockSelector],
  (scheduleByID, doctors, scheduleLockByID) => {
    const schedules = Object.keys(scheduleByID).map((s) => {
      const sch = scheduleByID[s] as Schedule;
      const doctID = doctors?.[sch.userID]?.doctID ?? "";
      return {
        ...sch,
        doctID,
      } as Schedule & { doctID: string };
    });

    const blockList = Object.keys(scheduleLockByID)
      .map((scloID) => scheduleLockByID[scloID])
      .filter((b) => !!b) as ScheduleLockView[];

    return {
      doctors,
      schedules,
      blockList,
    };
  }
);

export const getAllAppoAttachments = (
  state: RootState,
  props: { appoID?: string }
) =>
  createSelector([mainSelector], (state) => {
    const {
      docuIDByAppoID,
      documentByID,
      collectDocAttachByID,
      collectDocAttachByAppoID,
    } = state;
    const { appoID } = props;

    const listCollectDocu = appoID
      ? collectDocAttachByAppoID?.[appoID]?.map(
          (id) => collectDocAttachByID[id]
        ) ?? []
      : [];

    const listForms = appoID
      ? docuIDByAppoID?.[appoID]?.map((id) => documentByID[id]) ?? []
      : [];

    return {
      listCollectDocu,
      listForms,
    };
  });

export const getAppointment = (state: RootState, props: { appoID: string }) =>
  createSelector(
    [mainSelector, clinicDoctorRepository],
    (state, doctorByID) => {
      const appointment = state.appointmentByID[props.appoID];
      const doctor = appointment?.doctID
        ? doctorByID[appointment?.doctID]
        : undefined;

      return {
        doctor,
        appointment,
      };
    }
  );

export const appointmentPaymentView = (props: { appoID: string }) =>
  createSelector(
    [mainSelector, invoicesByIDSelector],
    (state, invoicesByID) => {
      const { appointmentByID, scheduleByID } = state;
      const { appoID } = props;
      const appointment = appointmentByID[appoID];
      const schedule = appointment?.sescID
        ? (scheduleByID[appointment.sescID] as Schedule)
        : undefined;
      const invoices =
        state.invoicesByAppoID[props.appoID]?.map?.(
          (invoID) => invoicesByID[invoID]
        ) ?? [];

      return {
        appointment,
        schedule,
        invoices,
      };
    }
  );

export const getInvoicesByAppoID = (props: { appoID: string }) =>
  createSelector(
    [invoicesByAppoIDRepository, invoicesByIDSelector],
    (invoicesByAppoID, invoicesByID) => {
      const { appoID } = props;
      return {
        invoices: (invoicesByAppoID?.[appoID] ?? [])
          .map((invoID) => invoicesByID[invoID])
          .filter((inv) => !!inv) as InvoiceView[],
      };
    }
  );

export const appointmentInvoicesView = (
  state: RootState,
  props: { appoID: string }
) =>
  createSelector(
    [mainSelector, invoicesByIDSelector],
    (state, invoicesByID) => {
      return {
        list:
          state.invoicesByAppoID[props.appoID]?.map?.(
            (invoID) => invoicesByID[invoID]
          ) ?? [],
      };
    }
  );

export const listSchedulesLock = createSelector(
  [scheduleLockSelector],
  (scheduleLockByID) => {
    const list = Object.keys(scheduleLockByID)
      .map((id) => {
        const scheduleLock = scheduleLockByID[id];
        if (!scheduleLock || scheduleLock?.origin !== ScheduleLockOrigin.lock) {
          return undefined;
        }
        return {
          ...scheduleLock,
          scloID: id,
        } as ScheduleLock;
      })
      .filter((b) => !!b)
      .sort((a, b) =>
        moment(b?.endAt).diff(moment(a?.endAt))
      ) as ScheduleLock[];
    return {
      list,
    };
  }
);

export const getListedSchedulesLock = createSelector(
  [scheduleLockSelector, listedScheduleLockSelector],
  (scheduleLockByID, list) => {
    return {
      locks: list
        .map((scloID) => scheduleLockByID[scloID])
        .filter((b) => !!b) as ScheduleLockView[],
    };
  }
);

export const getSchedulesLock = (state: RootState, props: { scloID: string }) =>
  createSelector([scheduleLockSelector], (scheduleLockByID) => {
    return {
      scheduleLock: scheduleLockByID[props.scloID],
    };
  });

export const getCalendarEvents = createSelector(
  [appointmentSelector, scheduleSelector],
  (appointmentByID, scheduleByID) => {
    return {
      listAppo: Object.keys(appointmentByID)
        .map((appoID) => appointmentByID[appoID])
        .filter((a) => !!a) as Appointment[],
      listSchedule: Object.keys(scheduleByID)
        .map((sescID) => scheduleByID[sescID])
        .filter((sch) => !!sch) as Schedule[],
    };
  }
);

export const appointmentListView = createSelector(
  [appointmentSelector],
  (appointmentByID) => {
    return {
      list: Object.keys(appointmentByID)
        .map((appoID) => appointmentByID[appoID])
        .sort((a, b) =>
          moment(b?.markedAt ?? "").diff(moment(a?.markedAt ?? ""))
        )
        .filter((a) => !!a) as Appointment[],
    };
  }
);

export const getAppointsByDay = (props: {
  //the date must be in the DASHUN format
  day: string;
}) =>
  createSelector(
    [appointmentsByDaySelector, appointmentSelector],
    (byDay, appointmentByID) => {
      return {
        appointments: (byDay?.[props.day] ?? [])
          .map((appoID) => appointmentByID[appoID])
          .filter((a) => !!a) as Appointment[],
      };
    }
  );

export const getAppointmentByMegrID = (
  state: RootState,
  props: { megrID: string }
) =>
  createSelector(
    [appoIDtByMegrIDSelector, appointmentSelector],
    (appoIDtByMegrID, appointmentByID) => {
      const appoID = appoIDtByMegrID[props.megrID];
      const appointment = appoID ? appointmentByID[appoID] : undefined;
      return {
        appointment,
      };
    }
  );

export const getSuggestView = (props: { appoID: string }) =>
  createSelector(
    [
      mainSelector,
      locationByIDSelector,
      scheduleLockSelector,
      doctorRepositoryByUserID,
    ],
    (state, locationByID, scheduleLockByID, doctorByUserID) => {
      const { appointmentByID } = state;
      const appointment = appointmentByID[props.appoID];

      const filterList = (item: Schedule & { doctID: string }) => {
        const clinID = appointment?.clinID;
        const doctID = appointment?.doctID;
        const type = appointment?.type;
        if (item.type !== type) {
          return false;
        }
        if (clinID && item.clinID !== clinID) {
          return false;
        }
        if (!clinID && !!item?.clinID) {
          return false;
        }
        if (doctID !== item.doctID) {
          return false;
        }

        return !item.deletedAt;
      };

      const schedules = Object.keys(state.scheduleByID)
        .map((s) => {
          const shedule = state.scheduleByID[s];
          const doctID = doctorByUserID?.[shedule?.userID ?? ""]?.doctID ?? "";
          let localeInfo = shedule?.locaID
            ? locationByID[shedule?.locaID]
            : undefined;
          return {
            ...shedule,
            doctID,
            localeInfo,
          } as Schedule & { doctID: string };
        })
        .filter((i) => filterList(i));

      const blockList = Object.keys(scheduleLockByID)
        .map((id) => scheduleLockByID[id])
        .filter((b) => !!b) as ScheduleLockView[];

      return {
        appointment,
        schedules,
        blockList,
      };
    }
  );

export const appointmentPendingReturn = (props: {
  patiID: string;
  doctID: string;
}) =>
  createSelector([pendingReturnSelector], (pendingReturn) => {
    const patientPendingReturn = pendingReturn[props.doctID]?.[props.patiID];
    return {
      patientPendingReturn,
    };
  });

export const getAppointmentView = (props: { appoID: string }) =>
  createSelector([appointmentSelector], (appointmentByID) => {
    const appointment = appointmentByID[props.appoID];
    return {
      appointment,
    };
  });

export const getOneAppointmentCommunication = (props: { appoID: string }) =>
  createSelector([appointmentCommunicationRepository], (communicationByID) => {
    return {
      communication: communicationByID[props.appoID],
    };
  });

// Actions
export function loadAllSchedules(f?: ScheduleFilter): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchSchedules(apiToken, f)
      .then(async (r) => {
        dispatch(actions.schedulesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function loadOneSchedule(sescID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchSchedule(apiToken, sescID)
      .then((r) => {
        dispatch(actions.scheduleLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function createOneSchedule(
  schedule: Schedule,
  sn?: boolean
): AppThunk<Promise<Schedule>> {
  const successNotification = sn ?? true;
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createSchedule(apiToken, schedule)
      .then((r) => {
        dispatch(actions.scheduleLoaded(r));
        if (successNotification) {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Realizado com sucesso!",
            })
          );
        }
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function updateOneSchedule(schedule: Schedule): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateSchedule(apiToken, schedule)
      .then((r) => {
        dispatch(actions.scheduleLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function removeOneSchedule(
  sescID: string,
  sn?: boolean
): AppThunk<Promise<void>> {
  const successNotification = sn ?? true;
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteSchedule(apiToken, sescID)
      .then((r) => {
        dispatch(actions.scheduleRemoved(r));
        if (successNotification) {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Realizado com sucesso!",
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function loadAppointments(
  f?: FilterAppointment
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCanceableAppointments(apiToken, f)
      .then((r) => {
        if (r) {
          dispatch(actions.appointmentsLoaded(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function createPatientForControlledAppointment(
  patient: OnboardingPatientForm,
  section: string = "general"
): AppThunk<Promise<OnboardingResponse | void>> {
  return async (dispatch) => {
    return dispatch(createControlledPatient(patient))
      .then((p) => {
        if (!p) {
          throw new Error("Falha ao criar paciente");
        }
        const {
          user: { patient },
        } = p;
        dispatch(patientActions.patientLoaded(patient!));
        return p;
      })
      .catch((e) => {
        dispatch(
          newNotification(section, {
            status: "error",
            message: "Não foi possível criar a conta do paciente",
            temporary: true,
          })
        );
        return Promise.reject("Não foi possível criar a conta do paciente");
      });
  };
}

export function createControlledAppointment(
  newAppo: NewAppointment,
  section: string = "general",
  notifyByPhone?: boolean,
  notifyDoctor?: boolean
): AppThunk<Promise<Appointment>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createAppointment(apiToken, newAppo)
      .then((r) => {
        dispatch(actions.appointmentLoaded(r));
        dispatch(actions.appointmentCalendarLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Agendamento realizado com sucesso!",
          })
        );
        dispatch(
          loadAllScheduleLocks({
            startDate: moment(newAppo.markedAt).utc().format(format.DASHUN),
            endDate: moment(newAppo.markedAt)
              .add(1, "day")
              .utc()
              .format(format.DASHUN),
          })
        );
        if (r?.appoID) {
          dispatch(
            sendControlledAppoEmail({
              appoID: r.appoID,
              sendTarget: "patient",
            })
          );
          if (notifyByPhone) {
            dispatch(
              sendControlledAppoPhone({
                appoID: r.appoID,
                sendTarget: "patient",
              })
            );
          }
          // wait for ratelimit
          if (notifyDoctor) {
            setTimeout(() => {
              dispatch(
                sendControlledAppoEmail({
                  appoID: r.appoID,
                  sendTarget: "doctor",
                })
              );
              if (notifyByPhone) {
                dispatch(
                  sendControlledAppoPhone({
                    appoID: r.appoID,
                    sendTarget: "doctor",
                  })
                );
              }
            }, 2000);
          }
        }
        return r;
      })
      .catch((e) => {
        let message = e.message;
        let temporary = true;
        if (e.message.indexOf("schedule unavailable") > -1) {
          section = "appointment-create";
          message =
            "Este horário não está disponível. Selecione outra data ou entre em contato com o profissional de saúde";
          temporary = false;
        }
        dispatch(
          newNotification(section, {
            status: "error",
            message,
            temporary,
          })
        );
        throw e;
      });
  };
}

export function sendControlledAppoEmail(mail: {
  appoID: string;
  sendTarget: "patient" | "doctor";
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return sendControlledAppointmentEmail(apiToken, mail)
      .then((r) => {
        if (mail.sendTarget === "patient") {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Email com instruções enviado ao paciente!",
            })
          );
        } else if (mail.sendTarget === "doctor") {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Email com instruções enviado ao profissional de saúde!",
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function sendControlledAppoPhone(mail: {
  appoID: string;
  sendTarget: "patient" | "doctor";
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return sendControlledAppointmentPhone(apiToken, mail)
      .then((r) => {
        if (mail.sendTarget === "patient") {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Mensagem com instruções enviado ao paciente!",
            })
          );
        } else if (mail.sendTarget === "doctor") {
          dispatch(
            newNotification("general", {
              status: "success",
              message:
                "Mensagem com instruções enviado ao profissional de saúde!",
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message:
              "Não foi possível enviar uma mensagem ao telefone do paciente",
          })
        );
      });
  };
}

export function sendPhoneAppointmentConfirmation(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return sendNotificationsPhoneAppointmentConfirmation(apiToken, appoID)
      .then((r) => {
        newNotification("general", {
          status: "success",
          message:
            "Solicitação de confirmação de presença enviado ao paciente!",
        });
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message:
              "Não foi possível enviar uma mensagem ao telefone do paciente",
          })
        );
      });
  };
}

export function copyNotification(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(
      newNotification("general", {
        status: "success",
        message: "Link copiado com sucesso.",
      })
    );
  };
}

export function editStatusAppointment(
  appoID: string,
  status: AppointmentStatus,
  reasonDescription?: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return sendEditStatusAppointment(apiToken, appoID, {
      status,
      reasonDescription,
    })
      .then((r) => {
        const resp = {
          ...r,
          nameCreatedBy: state.user?.myProfile?.name,
        };
        dispatch(actions.appointmentStatusChange(resp));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Status alterado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function appointmentCancelAndRefund(
  appoID: string,
  status: {
    status: AppointmentStatus.cancel | AppointmentStatus.canceledForReschedule;
  } & Omit<NewAppointmentStatus, "status">,
  paymID?: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return sendEditStatusAppointment(apiToken, appoID, status)
      .then(async (s) => {
        const resp = {
          ...s,
          nameCreatedBy: state.user?.myProfile?.name,
        };
        dispatch(actions.appointmentStatusChange(resp));
        if (paymID) {
          await dispatch(refundInvoicePayment(paymID));
        }
        const msgByStatus =
          s.status === AppointmentStatus.canceledForReschedule
            ? "Sugestão enviada"
            : "Agendamento cancelado";
        dispatch(
          newNotification("general", {
            status: "success",
            message: msgByStatus,
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointment(appoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointment(apiToken, appoID)
      .then(async (r) => {
        dispatch(actions.appointmentLoaded(r));
        dispatch(actions.appointmentCalendarLoaded(r));
        await fetchCachedSchedule(r.sescID);
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedAppointment(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const appointmentExist = Boolean(state.schedule.appointmentByID[appoID]);
    if (appointmentExist) {
      return Promise.resolve();
    }
    return dispatch(loadAppointment(appoID));
  };
}

//the date must be in the DASHUN format
export function fetchCachedAppointmentByDay(
  date: string,
  f?: FilterAppointment | undefined
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const list = state.schedule.appointmentsByDay?.[date] ?? [];
    if (list.length > 0) {
      return Promise.resolve();
    }
    const d = moment(date, format.DASHUN).local();
    return dispatch(
      loadAppointments({
        ...f,
        markedAtGte: moment.utc(d.startOf("day")).format(format.RFC3349),
        markedAtLte: moment.utc(d.endOf("day")).format(format.RFC3349),
      })
    );
  };
}

export function fetchCachedAppointmentByMegrID(
  megrID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const appoIDtByMegrIDExist = Boolean(
      state.schedule.appoIDtByMegrID[megrID]
    );
    if (appoIDtByMegrIDExist) {
      return Promise.resolve();
    }
    return dispatch(loadAppointments({ megrID }));
  };
}

export function loadAllAppointmentAttachments(
  appoID: string,
  filter?: FilterAttachment
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAttachmentsByappoID(apiToken, appoID, filter)
      .then(async (r) => {
        const atchs = r;
        const documents = await Promise.all(
          atchs
            .filter((att) => att.resource === ResourceType.document)
            .map((a) => {
              return fetchDocument(apiToken, a.resourceID);
            })
        );
        dispatch(actions.documentsLoaded(documents, appoID));

        const collectDocuments = await Promise.all(
          atchs
            .filter((att) => att.resource === ResourceType.collectDocument)
            .map((a) => {
              return fetchAppointmentCollectDocument(apiToken, a.resourceID);
            })
        );
        dispatch(actions.collectAppoDocumentsLoaded(collectDocuments, appoID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedSchedule(sescID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const locationExist = Boolean(state.schedule.scheduleByID[sescID]);
    if (locationExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneSchedule(sescID));
  };
}

export function loadInvoicesByAppoID(appoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchInvoicesByAppoID(apiToken, appoID)
      .then(async (r) => {
        dispatch(actions.invoicesLoaded(r, appoID));
        dispatch(actionsBilling.invoicesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedInvoicesByAppoID(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const list = state.schedule.invoicesByAppoID?.[appoID] ?? [];
    if (list.length > 0) {
      return Promise.resolve();
    }
    return dispatch(loadInvoicesByAppoID(appoID));
  };
}

export function clearInvoicesByAppoID(): AppThunk<void> {
  return (dispatch) => {
    dispatch(actions.clearInvoicesLoaded());
  };
}

export function createOneScheduleLock(
  lock: CreateScheduleLockForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createScheduleLock(apiToken, lock)
      .then((r) => {
        dispatch(actions.scheduleLockLoad(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAllScheduleLocks(
  f?: FilterScheduleLock
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchListScheduleLock(apiToken, f)
      .then(async (r) => {
        dispatch(actions.scheduleLockListLoad(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeOneScheduleLock(scloID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteScheduleLock(apiToken, scloID)
      .then((r) => {
        dispatch(actions.scheduleLockRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function addAppointmentConfirmation(
  appoID: string,
  patiID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return confirmAppointment(apiToken, appoID, { patiID })
      .then((r) => {
        dispatch(actions.appointmentConfirmationLoaded(appoID, r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchScheduleLocation(sescID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    let schedule = state.schedule.scheduleByID[sescID];
    try {
      if (!schedule) {
        await fetchSchedule(apiToken, sescID).then((r) => {
          schedule = r;
          dispatch(actions.scheduleLoaded(r));
        });
      }
      if (schedule?.locaID) {
        const locationExist = Boolean(
          state.location.locationByID[schedule.locaID]
        );
        if (locationExist) {
          return Promise.resolve();
        }
        const loc = await fetchLocation(apiToken, schedule.locaID);
        dispatch(locationActions.locaLoaded(loc));
      }
      return Promise.resolve();
    } catch (e) {
      dispatch(
        newNotification("general", {
          status: "error",
          message: (e as any).message,
        })
      );
      throw e;
    }
  };
}

export function loadAppoinmentStatusHistory(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return fetchAppointmentStatus(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentStatusLoaded(appoID, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createOneAppointmentReschedule(
  newSchedule: AppointmentRescheduleCreate
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createAppointmentReschedule(apiToken, newSchedule)
      .then((r) => {
        dispatch(actions.appoRescheduleLoaded(r));
        newNotification("general", {
          status: "success",
          message: "Agendamento remarcado com sucesso!",
        });
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPendingReturns(
  patiID: string,
  doctID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return fetchAppointsReturnPending(apiToken, { patiID, doctID })
      .then((r) => {
        dispatch(actions.pendingReturnLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getAppointmentCommunication(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointmentCommunication(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentCommunicationLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedAppointmentCommunication(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const communicationExist = Boolean(
      state.schedule.appointmentCommunicationByID[appoID]
    );
    if (communicationExist) {
      return Promise.resolve();
    }
    return dispatch(getAppointmentCommunication(appoID));
  };
}

export function sendAppointmentReviewNotification(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return sendAppointmentReview(apiToken, appoID)
      .then((r) => {
        newNotification("general", {
          status: "success",
          message: "Pesquisa de satisfação enviada!",
        });
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: "Não foi possível enviar a pesquisa de satisfação",
          })
        );
      });
  };
}

export function loadAppointmentReviews(
  f?: FilterAppointmentReview
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchListAppointmentReview(apiToken, f)
      .then((r) => {
        dispatch(actions.reviewsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function appointmentTransfer(
  appoID: string,
  doctID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return appointmentDoctorTransfer(apiToken, appoID, doctID)
      .then(async (r) => {
        dispatch(actions.appointmentLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Profissional de saúde alterado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function listScheduleSlots(
  f: FilterScheduleSlots
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchScheduleSlots(apiToken, f)
      .then(async (r) => {
        dispatch(actions.scheduleSlotLoaded(f.date, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function listCalendarEvents(
  filter: FilterCalendarEvents
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCanceableCalendarEvents(apiToken, filter)
      .then(async (r) => {
        if (!r) {
          return;
        }
        dispatch(actions.calendarEventsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function newAppointmentCheckIn(appoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createAppointmentCheckIn(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentCheckInLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Check-In realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeAppointmentCheckIn(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteAppointmentCheckIn(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentCheckInRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointmentCheckIn(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointmentCheckIn(apiToken, appoID)
      .then(async (r) => {
        dispatch(actions.appointmentCheckInLoaded(r));
      })
      .catch((e) => {
        throw e;
      });
  };
}

export function listAppointmentCheckIn(
  filter: FilterAppointmentCheckIn
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointmentCheckIns(apiToken, filter)
      .then(async (r) => {
        if (r) {
          dispatch(actions.appointmentCheckInsLoaded(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedAppointmentCheckIn(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const exist = Boolean(state.schedule.appointmentCheckInByID?.[appoID]);
    if (exist) {
      return Promise.resolve();
    }
    return dispatch(loadAppointmentCheckIn(appoID));
  };
}
