import React from "react";
import FileLoader from "containers/File/Private";
import FileDownloader from "containers/File/DownloadPrivate";
import VideoSessionButton from "containers/Appointment/VideoSession";
import { IconButton, Typography, CardContent } from "@material-ui/core";
import Icons from "@udok/lib/components/Icon";
import { prettyFilename } from "@udok/lib/internal/util";
import RenderMessages, {
  ChatMessageProps,
  EnlargeImage,
  LoadingButton,
} from "@udok/lib/components/Chat/DirectMessage/ChatMessage";
import RenderAudioPlayer from "containers/Chat/DirectMessage/RenderAudioPlayer";
import AppointmentByMegrID from "containers/Appointment/AppointmentByMegrID";

const ChatMessage = (
  porps: Omit<
    ChatMessageProps,
    | "messageImageComponent"
    | "messageAudioComponent"
    | "messageVideoComponent"
    | "messageArchiveComponent"
  >
) => {
  return (
    <RenderMessages
      {...porps}
      messageImageComponent={(props) => (
        <FileLoader fileID={props?.fileID}>
          {(p: {
            src: string;
            filename: string;
            loading: boolean;
            failed: boolean;
          }) => (
            <EnlargeImage
              style={{
                flex: 1,
                paddingTop: "100%",
              }}
              image={p.src}
              title={p.filename ?? "image"}
              handleDownloadImage={porps.handleDownloadImage}
              message={porps.message}
            />
          )}
        </FileLoader>
      )}
      messageAudioComponent={RenderAudioPlayer}
      messageVideoComponent={(props) => (
        <CardContent style={{ padding: 8, paddingBottom: 0 }}>
          <VideoSessionButton viseID={props.viseID} />
        </CardContent>
      )}
      messageArchiveComponent={(props) => (
        <FileDownloader
          fileID={props?.fileID}
          filleName={props?.text}
          component={LoadingButton}
          fullWidth
          style={{
            textAlign: "start",
          }}
          children={
            <Typography style={{ textOverflow: "ellipsis", flex: 1 }}>
              {prettyFilename(props?.text)}
            </Typography>
          }
          startIcon={
            <IconButton disabled>
              <Icons.InsertDriveFile style={{ fontSize: 48 }} />
            </IconButton>
          }
          endIcon={
            <IconButton disabled>
              {<Icons.CloudDownload style={{ fontSize: 48 }} />}
            </IconButton>
          }
        />
      )}
      cardActions={(props) => {
        if (!props?.megrID) {
          return null;
        }
        return <AppointmentByMegrID megrID={props.megrID} />;
      }}
    />
  );
};

export default ChatMessage;
