import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#178082"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.692 0A3.7 3.7 0 000 3.692v12.923a3.7 3.7 0 003.692 3.693h2.77v-1.846h-2.77a1.845 1.845 0 01-1.846-1.847V3.692c0-1.02.826-1.846 1.846-1.846H9.49c.141.036.26.112.347.202.4-.112.807-.202 1.24-.202h.98C10.868.663 10.065 0 9.232 0H3.692zm7.385 2.77a3.7 3.7 0 00-3.692 3.692v13.846A3.7 3.7 0 0011.077 24h9.23A3.7 3.7 0 0024 20.308V10.154c0-.98-.898-1.911-2.51-3.49-.223-.22-.465-.466-.692-.693-.227-.227-.472-.44-.692-.663-1.58-1.612-2.51-2.539-3.49-2.539h-5.54zm0 1.845h5.798c.667.17.663.97.663 1.789v1.904c0 .508.415.923.924.923h1.846c.92 0 1.846.003 1.846.923v10.154c0 1.02-.826 1.846-1.846 1.846h-9.231a1.845 1.845 0 01-1.846-1.846V6.462c0-1.02.825-1.847 1.846-1.847zm6.952 6.549a2.354 2.354 0 00-1.558.692l-1.154 1.183c.264-.267 1.233-.094 1.471.144l.49-.52c.228-.223.52-.36.809-.374a.943.943 0 01.72.288c.228.23.29.483.29.663 0 .303-.138.628-.376.866l-2.134 2.106c-.444.443-1.136.508-1.53.115a.57.57 0 10-.807.808c.404.403.952.605 1.5.605a2.31 2.31 0 001.644-.692l2.135-2.134c.45-.451.721-1.068.721-1.674 0-.559-.238-1.074-.635-1.47a2.09 2.09 0 00-1.586-.606zm-2.539 2.625c-.591 0-1.182.255-1.644.72l-1.99 1.962c-.451.45-.721 1.067-.721 1.673 0 .563.238 1.075.634 1.471.426.422.985.635 1.587.606a2.362 2.362 0 001.558-.692l1.01-1.039c-.264.267-1.234.094-1.472-.144l-.346.375c-.227.224-.52.36-.808.375a.944.944 0 01-.721-.288.94.94 0 01-.288-.664c0-.303.136-.627.375-.865l1.99-1.962c.447-.443 1.107-.505 1.5-.115a.606.606 0 00.836 0 .57.57 0 000-.808 2.116 2.116 0 00-1.5-.605z" />
    </svg>
  );
}

export default SvgComponent;
