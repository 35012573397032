import React from "react";
import Icons from "../Icon";
import {
  CircularProgress,
  makeStyles,
  IconButton,
  Typography,
  Link,
} from "@material-ui/core";
// @ts-ignore
import MicRecorder from "mic-recorder-to-mp3";
import AlertDialog from "@udok/lib/components/Dialog/AlertDialog";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import { getBrowserName } from "@udok/lib/internal/util";
import { BrowserSupportLinks } from "@udok/lib/internal/constants";
import clsx from "clsx";

import moment from "moment";
moment.locale("pt-br");

const PermissionError =
  "Permission denied, The request is not allowed by the user agent or the platform in the current context.";
const ErrorAlert = ({
  open,
  close,
  error,
}: {
  open: boolean;
  close: () => void;
  error: string;
}) => {
  const classes = useStyles({ disabled: false });
  const supportURL =
    BrowserSupportLinks?.[
      getBrowserName() as keyof typeof BrowserSupportLinks
    ] ?? "";

  const handleClose = React.useCallback(() => {
    close?.();
  }, [close]);

  return (
    <AlertDialog
      title="Falha ao iniciar gravação"
      visible={open}
      acceptText="OK"
      onClose={handleClose}
      description={
        PermissionError.includes(error) ? (
          <Typography>
            A plataforma Udok precisa ter acesso ao microfone do seu computador
            para que você possa gravar mensagens de voz.
            <br />
            <Link
              variant="body1"
              target="_blank"
              rel="noopener noreferrer"
              href={supportURL}
              className={clsx({ [classes.disableLink]: !supportURL })}
            >
              Verifique as configurações
            </Link>{" "}
            de permissão do seu navegador.
          </Typography>
        ) : (
          <Typography>
            <Link
              variant="body1"
              target="_blank"
              rel="noopener noreferrer"
              href={supportURL}
              className={clsx({ [classes.disableLink]: !supportURL })}
            >
              Verifique as configurações
            </Link>{" "}
            de permissão do seu navegador.
          </Typography>
        )
      }
      rejectionButton={false}
    />
  );
};

const [rendererAlert, promiseAlert] = createModal(ErrorAlert);
export default (props: {
  onRecordingFinished: (buffer: ArrayBuffer, blob: Blob) => void;
  loading?: boolean;
}) => {
  const { onRecordingFinished, loading } = props;
  const recorder = React.useMemo(() => new MicRecorder({ bitRate: 128 }), []);
  const initialEllapsed = "00:00";
  const [startedAt, setSA] = React.useState(moment());
  const [recording, setRC] = React.useState(false);
  const [ellapsed, setEllapsed] = React.useState(initialEllapsed);
  const [error, setE] = React.useState<string | undefined>();
  const { flicker, withAnimation } = useStyles({ disabled: false });

  const handleOpenAlert = React.useCallback((error: string) => {
    promiseAlert({ error });
  }, []);

  const startRecording = React.useCallback(() => {
    // Start recording. Browser will request permission to use your microphone.
    recorder
      .start()
      .then(() => {
        setRC(true);
        setSA(moment());
        setE(undefined);
      })
      .catch((e: any) => {
        setRC(false);
        setE(e?.message);
        handleOpenAlert(e?.message);
      })
      .finally(() => {
        setEllapsed(initialEllapsed);
      });
  }, [recorder, handleOpenAlert]);

  const acceptRecording = React.useCallback(() => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]: any) => {
        setRC(false);
        setE(undefined);
        onRecordingFinished(buffer, blob);
      })
      .catch((e: any) => {
        console.error(e);
        setRC(false);
        setE(e);
      })
      .finally(() => {
        setEllapsed(initialEllapsed);
      });
  }, [recorder, onRecordingFinished]);

  const trashRecording = React.useCallback(() => {
    recorder.stop();
    setRC(false);
    setE(undefined);
    setEllapsed(initialEllapsed);
  }, [recorder]);

  React.useEffect(() => {
    if (!recording) {
      return;
    }
    const t = setInterval(() => {
      const duration = moment.duration(moment().diff(startedAt));
      const ellapsed =
        duration.get("minutes").toString().padStart(2, "0") +
        ":" +
        duration.get("seconds").toString().padStart(2, "0");
      setEllapsed(ellapsed);
    }, 1000);

    return () => {
      clearInterval(t);
    };
  }, [recording, startedAt, setEllapsed]);

  React.useEffect(() => {
    return () => {
      try {
        recorder.stop();
      } catch (e) {
        console.warn(e);
      }
    };
  }, [recorder]);

  return (
    <div style={{ display: "flex" }}>
      {recording && !error ? (
        <>
          <IconButton onClick={trashRecording}>
            <Icons.CloseOutlined />
          </IconButton>
          <Typography
            color="textPrimary"
            component="div"
            style={{
              margin: "12px 0",
              lineHeight: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icons.FiberManualRecord
              className={`${flicker} ${withAnimation}`}
              color="error"
            />
            {ellapsed}
          </Typography>
        </>
      ) : null}
      {error ? (
        <IconButton
          title="Falha ao iniciar gravação"
          onClick={() => handleOpenAlert(error)}
        >
          <Icons.ErrorRounded color="error" />
        </IconButton>
      ) : null}
      <IconButton onClick={!recording ? startRecording : acceptRecording}>
        {recording ? (
          <Icons.Check color="primary" />
        ) : loading ? (
          <CircularProgress size={24} />
        ) : (
          <Icons.Mic />
        )}
      </IconButton>
      {rendererAlert}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  "@keyframes flicker": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.5,
    },
  },
  flicker: {
    animationName: "$flicker",
    animationDuration: "500ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out",
  },
  withAnimation: ({ disabled }: { disabled: boolean }) => ({
    animationPlayState: disabled ? "paused" : "running",
  }),
  disableLink: {
    pointerEvents: "none",
    color: theme.palette.text.primary,
  },
}));
