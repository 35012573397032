import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  MediaPostForm,
  MediaPost,
  FilterMediaPost,
} from "@udok/lib/api/models";
import {
  createMediaPost,
  listMediaPosts,
  getMediaPost,
  deleteMediaPost,
  updateMediaPost,
} from "@udok/lib/api/mediaPost";
import { getToken, UNAUTHORIZED } from "./auth";
import moment from "moment";

export type InitialState = {
  mediaPostByID: { [mepoID: number]: MediaPost };
};

// Reducers
const initialState: InitialState = {
  mediaPostByID: {},
};

class MediaPosts extends Hen<InitialState> {
  mediaPostLoaded(m: MediaPost) {
    this.state.mediaPostByID[m.mepoID] = m;
  }
  mediaPostsLoaded(ms: MediaPost[]) {
    ms.forEach((m) => {
      this.state.mediaPostByID[m.mepoID] = m;
    });
  }
  mediaPostRemoved(mepoID: number) {
    delete this.state.mediaPostByID[mepoID];
  }
}

export const [Reducer, actions] = hen(new MediaPosts(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.mediaPost;

export const getListPosts = createSelector([mainSelector], (state) => {
  return {
    list: Object.keys(state.mediaPostByID)
      .map((mepoID) => state.mediaPostByID[Number(mepoID)])
      .sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt))),
  };
});

export const getOnePost = (props: { mepoID: number }) =>
  createSelector(mainSelector, (state) => {
    return {
      mediaPost: state.mediaPostByID[props.mepoID],
    };
  });

// Actions
export function createNewMediaPost(
  form: MediaPostForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createMediaPost(apiToken, form)
      .then((r) => {
        dispatch(actions.mediaPostLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Criado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadMediaPosts(
  filter?: FilterMediaPost
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return listMediaPosts(apiToken, filter)
      .then((r) => {
        dispatch(actions.mediaPostsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneMediaPost(mepoID: number): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return getMediaPost(apiToken, mepoID)
      .then((r) => {
        dispatch(actions.mediaPostLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedMediaPost(mepoID: number): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const postExist = Boolean(state.mediaPost.mediaPostByID[mepoID]);
    if (postExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneMediaPost(mepoID));
  };
}

export function removeMediaPost(mepoID: number): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return deleteMediaPost(apiToken, mepoID)
      .then((r) => {
        dispatch(actions.mediaPostRemoved(r.mepoID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function changeMediaPost(
  form: MediaPostForm & { mepoID: number }
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateMediaPost(apiToken, form)
      .then((r) => {
        dispatch(actions.mediaPostLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
