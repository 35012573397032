import * as React from "react";
import { useDispatch } from "react-redux";
import Form from "containers/Quickresponse/Form";
import { AppDispatch } from "ducks/state";
import { createOneQuickResponse } from "ducks/messaging";
import { QuickResponse } from "@udok/lib/api/models";

export type QuickResponseCreateProps = {
  initialValues?: Partial<QuickResponse>;
  onSuccess?: (r: QuickResponse) => void;
};

const QuickResponseCreate = (props: QuickResponseCreateProps) => {
  const { initialValues, onSuccess } = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleSubmit = React.useCallback(
    (err: any, values: any, form: any) => {
      if (err) {
        return;
      }
      setLoading(true);
      const response: Partial<QuickResponse> = {
        title: values.title,
        value: values.response,
        keywords: values.keywords,
      };
      dispatch(createOneQuickResponse(response as QuickResponse))
        .then((r) => {
          onSuccess?.(r);
        })
        .finally(() => setLoading(false));
    },
    [dispatch, onSuccess]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      loading={loading}
    />
  );
};

export default QuickResponseCreate;
