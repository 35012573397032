import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import { SharedResource, SharedResourceFilter, SharedResourceForm } from "@udok/lib/api/models";

export const createSharedResource = async (apiToken: string, form: SharedResourceForm) => {
    return axios
      .post(`${process.env.REACT_APP_API_PATH}/shared-resources`, form, {
        headers: { Authorization: apiToken, },
      })
      .then((r) => {
        return r.data.data.item as SharedResource;
      })
      .catch((e) => {
        const err = deriveErrorMessage(e, "shre1");
        return Promise.reject(new Error(err));
      });
  };

export async function fetchSharedResources(apiToken: string, filter?: SharedResourceFilter) {
  const f = filter ? toQueryString(filter) : "";
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/shared-resources${f}`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        return r.data.data.items as SharedResource[];
      })
      .catch((e) => {
        const err = deriveErrorMessage(e, "shre2");
        return Promise.reject(new Error(err));
      });
  }
  
  export async function fetchSharedResource(apiToken: string, shreID: string) {
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/shared-resources/${shreID}`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        return r.data.data.item as SharedResource;
      })
      .catch((e) => {
        const err = deriveErrorMessage(e, "shre3");
        return Promise.reject(new Error(err));
      });
  }

  export const deleteSharedResource = async (apiToken: string, shreID: string) => {
    return axios
      .delete(`${process.env.REACT_APP_API_PATH}/shared-resources/${shreID}`, {
        headers: { Authorization: apiToken, },
      })
      .then((r) => {
        return r.data.data.item as SharedResource;
      })
      .catch((e) => {
        const err = deriveErrorMessage(e, "shre4");
        return Promise.reject(new Error(err));
      });
  };

  export const updateSharedResource = async (
    apiToken: string,
    form: SharedResourceForm & { shreID: string }
  ) => {
    return axios
      .put(`${process.env.REACT_APP_API_PATH}/shared-resources/${form.shreID}`, form, {
        headers: { Authorization: apiToken, },
      })
      .then((r) => {
        return r.data.data.item as SharedResource;
      })
      .catch((e) => {
        const err = deriveErrorMessage(e, "shre5");
        return Promise.reject(new Error(err));
      });
  };