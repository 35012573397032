import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Icons from "@udok/lib/components/Icon";
import IconButton from "@material-ui/core/IconButton";
import SearchModal from "containers/Quickresponse/SearchModal";
import { QuickResponse } from "@udok/lib/api/models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

const Composer = (props: {
  userID: string;
  currentUserID?: string;
  onResponse: (txt: string) => void;
  style?: React.CSSProperties;
}) => {
  const { onResponse, style } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const acceptResponse = (q: QuickResponse) => {
    handleClose();
    onResponse(q.value);
  };

  return (
    <>
      <IconButton onClick={handleOpen} className={classes.root} style={style}>
        <Icons.CommentFlashOutline />
      </IconButton>
      <SearchModal
        open={open}
        onDismiss={handleClose}
        onSelect={acceptResponse}
      />
    </>
  );
};

export default Composer;
