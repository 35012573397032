import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogTitleProps,
  Typography,
  CircularProgress,
  TypographyProps,
} from "@material-ui/core";
import Icons from "@udok/lib/components/Icon";

export interface AlertDialogProps {
  visible: boolean;
  title?: string;
  description?: string | React.ReactNode | null;
  loading?: boolean;
  acceptText?: string;
  rejectionText?: string;
  rejectionButton?: boolean;
  titletypography?: TypographyProps;
  descriptionTypography?: TypographyProps;
  icon?: string | JSX.Element | null;
  iconProps?: React.SVGProps<SVGSVGElement>;
  dialogTitleProps?: DialogTitleProps;
  onAcept?: () => void;
  onClose: () => void;
}

const AlertDialog = ({
  acceptText,
  rejectionText,
  visible,
  title,
  description,
  loading,
  titletypography,
  icon,
  iconProps,
  rejectionButton = true,
  dialogTitleProps,
  descriptionTypography,
  onClose,
  onAcept,
}: AlertDialogProps) => {
  const MappedIcons = {
    ...(Icons as any),
  };
  const Icon = icon
    ? typeof icon === "string"
      ? MappedIcons[icon]
      : undefined
    : undefined;
  return (
    <>
      <Dialog
        open={visible}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title" {...dialogTitleProps}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {icon ? (
              typeof icon === "string" ? (
                <Icon width={50} height={50} {...iconProps} />
              ) : (
                icon
              )
            ) : null}
            {title ? (
              <Typography
                {...titletypography}
                style={{ flex: 1, marginLeft: icon ? 10 : undefined }}
              >
                {title}
              </Typography>
            ) : null}
          </div>
        </DialogTitle>
        {description && (
          <DialogContent>
            <Typography variant="body1" {...descriptionTypography}>
              {description}
            </Typography>
          </DialogContent>
        )}
        <DialogActions>
          {rejectionButton ? (
            <Button
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClose();
              }}
              color="primary"
            >
              {rejectionText ?? "Não"}
            </Button>
          ) : null}
          <Button
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onAcept?.();
              onClose();
            }}
            color="primary"
          >
            {loading ? <CircularProgress size={24} /> : acceptText ?? "Sim"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
