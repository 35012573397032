import React from "react";
import Avatar from "containers/User/Avatar";
import { ComponentProps } from "@udok/lib/components/Chat/DirectMessage/ChatMessage";
import RenderAudioPlayer from "@udok/lib/components/AudioPlayer";
import { useFileLoader } from "hooks/files";

const ChatAudioPlayer = (props: { fileID: string } & ComponentProps) => {
  const { fileID, ...others } = props;

  const [src] = useFileLoader(fileID);

  return (
    <RenderAudioPlayer
      fileID={fileID}
      src={src}
      avatar={<Avatar style={{ margin: "10px 15px" }} userID={props?.userID} />}
      {...others}
    />
  );
};

export default ChatAudioPlayer;
